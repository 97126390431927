import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import Login from './Login'
import Main from './Main'

export default class WindowHandler extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

componentDidMount() {
    //this.readAuthCodeLocalStorage();
  }

  setAuthentication = (token) => {
    localStorage.setItem('token', token);
    this.forceUpdate();
  }

  getAuthToken = () => {
    return localStorage.getItem("token");
  }

  resetAuthentication = () => {
    this.setAuthentication("");
    this.forceUpdate();
  }

  setColorTheme = (colorTheme) => {
    const allowedColorThemes = ["dark","light"];
    colorTheme = colorTheme.toLowerCase();
    if(allowedColorThemes.includes(colorTheme)){
      localStorage.setItem('colorTheme', colorTheme);
      this.forceUpdate();
    }
  }

  getColorTheme = () => {
    let colorTheme = localStorage.getItem("colorTheme");
    if(colorTheme){
      return colorTheme;
    }
    else{
      return "banan";
    }
  }

  render() {

    if(!this.getAuthToken()){
      return(
        <div>
          <Login colorTheme={this.getColorTheme} setAuthentication={this.setAuthentication}/>
        </div>
      )
    }
    else{
      return(
        <div>
          <Main colorTheme={this.getColorTheme} setColorTheme={this.setColorTheme} resetAuthentication={this.resetAuthentication} auth_token={this.getAuthToken()}/>
        </div>
      )
    }

  }

}
