import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class CreateNewEventModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        dragOffsets:null,
        mqttTopic:"",
        eventObjectName:"",
        eventDescription:"",
        eventObjectDescription:"",
        systemName:"",
        eventValue:1.0,
        eventValueBitNumber:0,
        responseText:"",
        eventCreated:false,
        bitValueSelection:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.innerWidth < 767){
      width = window.innerWidth*0.8;
    }
    let height = "650px";
    if(window.innerHeight < 767){
      height = window.innerHeight*0.8;
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({alarmCreated:false, mqttTopic:"",eventObjectName:"",eventDescription:"",eventObjectDescription:"",systemName:"",eventValue:1.0,eventValueBitNumber:"",responseText:"",bitValueSelection:false});
  }


  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  createNewEvent = () => {
    let params = { 
        "mqtt_topic": this.state.mqttTopic,
        "event_object_name":this.state.eventObjectName,
        "event_description":this.state.eventDescription,
        "event_object_description":this.state.eventObjectDescription,
        "system_name":this.state.systemName,
        "operator_name":"Controlsystem",
        "event_from_operator":false,
        "event_value":this.state.eventValue
    };
    if(this.state.bitValueSelection){
      params["event_value_bit_number"] = this.state.eventValueBitNumber;
    }
    this.setState({ isLoading:true, error:false, responseText: ""});
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)};
    let url = getServerUrl() + "createevent?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        this.props.update();
        console.log(data);
        this.setState({responseText: data,eventCreated:true});
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
    });
  }

  handleTopicChange = (event) => {
    event.target.value = event.target.value.replace("#","")
    event.target.value = event.target.value.replace("+","")
    this.setState({mqttTopic:event.target.value});
  }

  handleEventObjectNameChange = (event) => {
    this.setState({eventObjectName:event.target.value});
  }

  handleEventDescriptionChange = (event) => {
    this.setState({eventDescription:event.target.value});
  }

  handleEventObjectDescriptionChange = (event) => {
    this.setState({eventObjectDescription:event.target.value});
  }

  handleSystemNameChange = (event) => {
    let systemName = event.target.value;
    systemName = systemName.toUpperCase();
    this.setState({systemName:systemName});
  }

  handleEventValueChange = (event) => {
    this.setState({eventValue:event.target.value});
  }

  handleEventValueBtnChange = (value) => {
    if(value){
      value = 1.0;
    }
    else{
      value = 0.0;
    }
    this.setState({eventValue:value});
  }

  handleEventValueBitNumberChange = (event) => {
    let value = parseInt(event.target.value);
    if(event.target.value === ""){
      value = 0;
    }
    if(value >= 0 && value < 64){
      this.setState({eventValueBitNumber:value});
    } 
  }

  setBitValueSelection = (value) => {
    this.setState({bitValueSelection:value});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"2",
        padding:"5px"
    };

    let createNewEventButton = <></>;
    if(this.state.mqttTopic.length > 0 && this.state.eventObjectDescription.length > 0 && this.state.eventDescription.length > 0 && this.state.systemName.length > 0 &&
     !isNaN(this.state.eventValue) && ((!this.state.bitValueSelection || this.state.bitValueSelection) && !isNaN(this.state.eventValueBitNumber))){
      createNewEventButton = <button style={{color:textColor, borderColor:textColor}} type="button" className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.createNewEvent()}>Create</button>;
    }

    let responseText = <></>;
    if(this.state.eventCreated){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }


    let eventValueDiv = <></>;
    if(this.state.bitValueSelection){
      eventValueDiv = <div>
                        <div className="row my-1">
                        <label style={{color:textColor}} className="form-label" htmlFor="createNewEventValue">Alarm value:</label>
                          <div className="col">
                            
                            <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Bit number" step="1" type="number" id="createNewEventValue" onChange={(event) => this.handleEventValueBitNumberChange(event)} className="form-control" value={this.state.eventValueBitNumber}/>
                          </div>
                          <div className="col">
                            <div className="btn-group">
                              <button style={{color:textColor, borderColor:textColor}} className={this.state.eventValue ? "btn btn-success w-100" : "btn btn-outline-secondary w-100"} onClick={() => this.handleEventValueBtnChange(true)}>true</button>
                              <button style={{color:textColor, borderColor:textColor}} className={this.state.eventValue ? "btn btn-outline-secondary w-100" : "btn btn-success w-100"} onClick={() => this.handleEventValueBtnChange(false)}>false</button>
                            </div>
                          </div>
                        </div>
                      </div>
    }
    else{
      eventValueDiv = <div className="form-outline">
                        <label style={{color:textColor}} className="form-label" htmlFor="createNewEventValue">Event value:</label>
                        <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} placeholder="numeric value" type="number" id="createNewEventValue" onChange={(event) => this.handleEventValueChange(event)} className="form-control" value={this.state.eventValue}/>
                      </div>
    }

    if(this.props.show){
      return(
      <div>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>



        <div className="row">
          <div className="col-sm-12 text-center">
              <h5 style={{color:textColor}}> Create new Event </h5>
          </div>
        </div>      

        <div className="row">
          <div className="text-center">
            {responseText}
          </div>
        </div>

        <div className="w-100">
               <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewEventTopic">MQTT topic:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="aqs-xx-xx/motor/p1/commando_register" type="text" id="createNewEventTopic" onChange={(event) => this.handleTopicChange(event)} className="form-control" value={this.state.mqttTopic}/>
                </div>

                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewEventObjectName">Object name:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="P1" type="text" id="createNewEventObjectName" onChange={(event) => this.handleEventObjectNameChange(event)} className="form-control" value={this.state.eventObjectName}/>
                </div>


                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewEventObjectDescription">Object description:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Inlet water pump" type="text" id="createNewEventObjectDescription" onChange={(event) => this.handleEventObjectDescriptionChange(event)} className="form-control" value={this.state.alarmLabel}/>
                </div>

                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewEventEventDescription">Event description:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="Mode changed to Auto mode" type="text" id="createNewEventEventDescription" onChange={(event) => this.handleEventDescriptionChange(event)} className="form-control" value={this.state.eventDescription}/>
                </div>

                <div className="form-outline">
                  <label style={{color:textColor}} className="form-label" htmlFor="createNewEventSystemName">System name:</label>
                  <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} autoComplete="off" placeholder="aqs-xx-xx" type="text" id="createNewEventSystemName" onChange={(event) => this.handleSystemNameChange(event)} className="form-control" value={this.state.systemName}/>
                </div>

                {/*<label className="form-label" htmlFor="createNewAlarmValueSelection">Value selection:</label>  */}     
                <div>
                  <p style={{color:textColor}}> Value selection: </p>
                  <div className="text-center">
                    <div className="btn-group">
                      <button style={{color:textColor, borderColor:textColor}} className={this.state.bitValueSelection ? "btn btn-outline-secondary w-100" : "btn btn-success w-100"} onClick={() => this.setBitValueSelection(false)}>Numeric</button>
                      <button style={{color:textColor, borderColor:textColor}} className={this.state.bitValueSelection ? "btn btn-success w-100" : "btn btn-outline-secondary w-100"} onClick={() => this.setBitValueSelection(true)}>Bit</button>
                    </div>
                  </div>
                </div>

                {eventValueDiv}
                

                <div className="text-center my-3">
                  {createNewEventButton}
                </div>
            
          </div>
    


          
        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
