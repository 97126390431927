import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import aqsLogoBlue from './images/aqs-logo-blue.png'
import aqsLogoWhite from './images/aqs-logo-white.png'

import {getServerUrl} from './ApplicationNetworking'

export default class Login extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        username:"",
        password:"",
        errorText:""
      }
    }

  handleLogin = () => {
    this.getAuthToken();
  }

  handleUsernameChange = (event) => {
      this.setState({username:event.target.value});
  }

  handlePasswordChange = (event) => {
      this.setState({password:event.target.value});
  }

 getAuthToken = () => {
   this.setState({ isLoading:true, error:false, errorText: ""}); 
   let url = getServerUrl() + "authentication_token?username=" + this.state.username + "&password=" +this.state.password; 
   fetch(url)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        let auth_token = JSON.parse(data).token;
        if(auth_token){
          this.props.setAuthentication(auth_token);
          this.setState({username:"",password:"",errorText: ""});
          this.sendMetaData(auth_token);
        }

     })
     .catch((response) => {
      let responseStatusText = "";
      if(response.statusText === undefined){
        responseStatusText = "No response from server!"
      }
      else{
        responseStatusText = response.statusText;
      }
      this.setState({isLoading: false, error:true, errorText: responseStatusText});
    });
  }

  sendMetaData = (auth_token) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "os": navigator.platform,
          "screensize":window.screen.width+"x"+window.screen.height})
      };
    let url = getServerUrl() + "metadata?auth_token="+auth_token;
    fetch(url, requestOptions);
  }


  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let aqsLogo = this.props.colorTheme() === "light" ? aqsLogoBlue : aqsLogoWhite;
    let textColor = this.props.colorTheme() === "light" ? "black" : "white";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";

    let loginButton = <button style={{backgroundColor:backgroundColor, color:textColor, borderColor:textColor}} disabled type="button" className="btn btn-primary btn-block mb-4">Login</button>;
    if(this.state.username.length > 0 && this.state.password.length > 0){
      loginButton = <button style={{backgroundColor:backgroundColor, color:textColor, borderColor:textColor}} type="button" className="btn btn-primary btn-block mb-4" onClick={() => this.handleLogin()}>Login</button>;
    }

    let pageStyle = {
      minWidth:"100vw", 
      minHeight:"100vh", 
      overflow:"hidden", 
      backgroundColor:backgroundColor
    };

    return(
      <div style={pageStyle} className="loginPage border">

      <div className="row my-3">
        <div className="text-center">
          <img src={aqsLogo} style={{maxWidth:"50vh", height:"auto"}} alt="Logo"/>
          </div>
      </div>

      <div className="row justify-content-md-center">
        <div style={{backgroundColor:this.props.colorTheme() === "light"?"white":"#323232", maxWidth:"50vw"}} className="col-md-auto mx-auto border rounded">

            <div className="row my-2">
              <div className="text-center">
                <p className="text-danger">{this.state.errorText}</p>
              </div>
            </div>

            <form>

              <div  className="form-outline mb-4">
                <label style={{color:textColor}} className="form-label" htmlFor="loginInFormUsername">Username:</label>
                <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="username" id="loginInFormUsername" onChange={(event) => this.handleUsernameChange(event)} className="form-control" value={this.state.username}/>
              </div>

              <div className="form-outline mb-4">
                <label style={{color:textColor}} className="form-label" htmlFor="loginInFormPassword">Password:</label>
                <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="password" id="loginInFormPassword" onChange={(event) => this.handlePasswordChange(event)} className="form-control" value={this.state.password}/>
              </div>

              <div className="text-center">
                {loginButton}
              </div>

            </form>

        </div>
      </div>

      </div>
    )

    }

}
