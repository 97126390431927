import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faPencil } from '@fortawesome/free-solid-svg-icons'

import NumericEntryFieldModal from './NumericEntryFieldModal'

import {getServerUrl} from '../ApplicationNetworking'

export default class MotorModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        showNumericFieldModal:false,
        numericFieldModalData:{},
        dragOffsets:null,
        editUnlocked:false,
        showRunTimeInfo:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter();
      this.setState({editUnlocked:false});
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.innerWidth < 480){
      width = parseInt(window.innerWidth*0.8) + "px"; // 80vw
    }
    let height = "480px";
    if(window.innerHeight < 480){
      height = parseInt(window.innerHeight*0.8) + "px"; // 80vh
      this.setState({showRunTimeInfo:false});
    }
    else{
      this.setState({showRunTimeInfo:true});
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({showNumericFieldModal:false, commandSent:false});
  }

  openNumericFieldModal = (jsonData) => {
    if(this.state.editUnlocked){
      this.setState({showNumericFieldModal:true, numericFieldModalData:jsonData});
    }
  }

  closeNumericFieldModal = () => {
    this.setState({showNumericFieldModal:false});
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  toggleEditUnlock = () => {
    this.setState({editUnlocked:!this.state.editUnlocked});
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  insertEvent = (eventDescription) => {
    let objectName = this.props.customProps.objectName;
    let objectDescription = this.props.customProps.objectDescription;
    let systemName = this.props.unitName;
    let userName = this.props.customProps.userName;
    if(eventDescription && objectName && objectDescription && systemName && userName){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "event_object_name": objectName,
          "event_object_description":objectDescription,
          "event_description":eventDescription,
          "system_name":systemName.toUpperCase(),
          "operator_name":userName,
          "event_from_operator":true})};
      let url = getServerUrl() + "insertevent?auth_token=" + this.props.auth_token;
      fetch(url, requestOptions)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          // pass
       })
       .catch((response) => {
        this.setState({isLoading: false, error:true});
      });
    }
  }

  setManualStart = (commandoRegister) => {
    if (!commandoRegister !== undefined && commandoRegister !== null){
      let newValue = commandoRegister | Math.pow(2,1);
      if(newValue !== commandoRegister){
        this.sendNewCommandoRegisterValue(newValue);
        this.insertEvent("Manual start");
      }
    }
  }

  setManualStop = (commandoRegister) => {
    if (!commandoRegister !== undefined && commandoRegister !== null){
      let newValue = commandoRegister & (commandoRegister - Math.pow(2,1));
      if(newValue !== commandoRegister){
        this.sendNewCommandoRegisterValue(newValue);
        this.insertEvent("Manual stop");
      }
    }
  }

  setAutoMode = (commandoRegister) => {
    if (!commandoRegister !== undefined && commandoRegister !== null){
      let newValue = commandoRegister | Math.pow(2,0);
      if(newValue !== commandoRegister){
        this.sendNewCommandoRegisterValue(newValue);
        this.insertEvent("Auto mode");
      } 
    }
  }

  setManualMode = (commandoRegister) => {
    if (!commandoRegister !== undefined && commandoRegister !== null){
      let newValue = commandoRegister & (commandoRegister - Math.pow(2,0));
      if(newValue !== commandoRegister){
        this.sendNewCommandoRegisterValue(newValue); 
        this.insertEvent("Manual mode"); 
      }
    }
  }

  setReset = (commandoRegister) => {
    if (!commandoRegister !== undefined && commandoRegister !== null){
      let newValue = commandoRegister | Math.pow(2,2);
      if(newValue !== commandoRegister){
        this.sendNewCommandoRegisterValue(newValue);
        this.insertEvent("Manual reset");
      }
    }
  }

  sendNewCommandoRegisterValue = (value) => {
    this.setState({isLoading: true, error:false, responseText: "", commandSent:true});
     const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "topic": this.props.dataPath + "/commando_register/set",
          "payload":{"value":value}})
    };
    let url = getServerUrl() + "sendvalue?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        this.setState({isLoading: false, error:false, responseText: ""});
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
    });
    
  }


  convertMinutesToHHMM = (totalMinutes) => {
    let retValue = "";
    if(!isNaN(totalMinutes)){
      let minutes = totalMinutes % 60;
      let hours = (totalMinutes - minutes)/60
      if(hours > 0){
        if(hours < 10){
          retValue = "0" + hours + " h "
        }
        else{
          retValue = hours + " h "
        }
        
      }
      if(minutes > 0){
        if(minutes < 10){
          retValue += "0" + minutes + " m "
        }
        else{
          retValue += minutes + " m "
        }
      }
    }
    return retValue
  }



  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let styleParameters = {
          fontSize:"calc(50% + 0.6vw)",
          color:textColor,
          marginLeft:"15px"
    };

    let data = {};
    if(this.props.data !== undefined){
      data = this.props.data;
    }

    let commandoRegister = NaN;
    let autoModeBtnClass = "btn btn-outline-secondary w-100";
    let manualModeBtnClass = "btn btn-outline-secondary w-100";
    let manualStopBtnClass = "btn btn-outline-secondary w-100";
    let manualStartBtnClass = "btn btn-outline-secondary w-100";
    let resetBtnClass = "btn btn-outline-secondary w-40";
    if(data.hasOwnProperty(this.props.dataPath + "/commando_register")){
      commandoRegister = data[this.props.dataPath + "/commando_register"].value;
      if(commandoRegister & Math.pow(2,0)){
        autoModeBtnClass = "btn btn-success w-100";
      }
      else{
        manualModeBtnClass = "btn btn-success w-100";
      }
      if(commandoRegister & Math.pow(2,1)){
        manualStartBtnClass = "btn btn-success w-100";
      }
      else{
        manualStopBtnClass = "btn btn-success w-100";
      }
      if(commandoRegister & Math.pow(2,2)){
        resetBtnClass = "btn btn-success w-40";
      }
    }

    let editLockSymbol = this.state.editUnlocked ? faLockOpen : faLock;

    /* Reset button */
    let resetButton = <></>;
    if(this.props.customProps.showResetOutput !== undefined){
      if(this.props.customProps.showResetOutput){
      resetButton = <div>
                      <div className="row my-2">
                        <div className="col-12">
                           <div className="text-center">
                            <button data-toggle="tooltip" disabled={!this.state.editUnlocked} data-placement="top" title="Reset signal to motor or drive" onClick={() => this.setReset(commandoRegister)} className={resetBtnClass}> Reset </button>
                          </div>
                        </div>
                      </div>
                    </div>;
      }
    }

    /* Manual speed */
    let manualSpeedPv = NaN;
    let manualSpeedDiv = <div className="border rounded">
                          <i style={styleParameters} >Manual speed: No data! </i>
                         </div>;
    if(data.hasOwnProperty(this.props.dataPath + "/manual_speed")){
        manualSpeedPv = parseFloat(data[this.props.dataPath + "/manual_speed"].value.toFixed(2));
        manualSpeedDiv = <div className="border rounded">
                            <i style={styleParameters} >Manual speed: {manualSpeedPv} %
                              <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                              {"dataPath":this.props.dataPath+"/manual_speed/set","currentValue":manualSpeedPv,"unitOfMeasurement":"%", 
                              "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", manual speed", "min":0, "max":100,
                              "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                            </i>
                          </div>;
    }

    /* Auto speed */
    let autoSpeedPv = NaN;
    let autoSpeedDiv = <></>;
    if(this.props.customProps.showAutoSpeedSetpoint !== undefined){
        if(this.props.customProps.showAutoSpeedSetpoint){
          if(data.hasOwnProperty(this.props.dataPath + "/auto_speed")){
            autoSpeedPv = parseFloat(data[this.props.dataPath + "/auto_speed"].value.toFixed(2));
            autoSpeedDiv = <div className="border rounded">
                                <i style={styleParameters} >Auto speed: {autoSpeedPv} %
                                  <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                                  {"dataPath":this.props.dataPath+"/auto_speed/set","currentValue":autoSpeedPv,"unitOfMeasurement":"%", 
                                  "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", auto speed", "min":0, "max":100,
                                  "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                                </i>
                              </div>;
                            }
          else{
            autoSpeedDiv = <div className="border rounded">
                            <i style={styleParameters} >Auto speed: No data! </i>
                           </div>;
        }
      }
    }

    let totalTimeRunningPV = "No data!";
    if(data.hasOwnProperty(this.props.dataPath + "/total_time_running")){
      totalTimeRunningPV = data[this.props.dataPath + "/total_time_running"].value;
      totalTimeRunningPV = this.convertMinutesToHHMM(totalTimeRunningPV);
    }
    let totalAmountStartPV = "No data!";
    if(data.hasOwnProperty(this.props.dataPath + "/total_amount_start")){
      totalAmountStartPV = data[this.props.dataPath + "/total_amount_start"].value;
    }

    let runTimeInfo = <></>;
    if(this.state.showRunTimeInfo){
      runTimeInfo = <div>
                      <p className="my-0" style={styleParameters}>Total amount start: {totalAmountStartPV} </p>
                      <p className="my-0" style={styleParameters}>Total time running: {totalTimeRunningPV}</p>
                    </div>
            
    }

    if(this.props.show){
      return(
      <div>
      <NumericEntryFieldModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} show={this.state.showNumericFieldModal} close={this.closeNumericFieldModal} data={this.state.numericFieldModalData}/>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor, float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
              <FontAwesomeIcon onClick={() => this.toggleEditUnlock()} className="float-start" size="2x" style={{cursor:"pointer", marginRight:"10px", color:textColor}} icon={editLockSymbol} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.unitName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.customProps.objectName} - {this.props.customProps.objectDescription}</h5>
              </div>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-1"></div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" style={{outlineColor:"#053d57"}} disabled={!this.state.editUnlocked} data-placement="top" title="Set motor in Manual mode" onClick={() => this.setManualMode(commandoRegister)} className={manualModeBtnClass}> Manual </button>
              </div>
            </div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" disabled={!this.state.editUnlocked} data-placement="top" title="Set motor in Auto mode" onClick={() => this.setAutoMode(commandoRegister)} className={autoModeBtnClass}> Auto </button>
              </div>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row my-2">
            <div className="col-1"></div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" disabled={!this.state.editUnlocked} data-placement="top" title="Set motor in Manual stop" onClick={() => this.setManualStop(commandoRegister)} className={manualStopBtnClass}> Stop </button>
              </div>
            </div>
            <div className="col-6"></div>
          </div>

          <div className="row my-2">
            <div className="col-1"></div>
            <div className="col-5">
              <div className="text-center">
                <button data-toggle="tooltip" disabled={!this.state.editUnlocked} data-placement="top" title="Set motor in Manual start" onClick={() => this.setManualStart(commandoRegister)} className={manualStartBtnClass}> Start </button>
              </div>
            </div>
            <div className="col-6"></div>
          </div>

          {resetButton}

          <div className="row my-1">
            <div className="col-sm-12">
              {manualSpeedDiv}
            </div>
          </div>

          <div className="row my-1">
            <div className="col-sm-12">
              {autoSpeedDiv}
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-12">
              {runTimeInfo}
            </div>
          </div>
          
        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
