import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class ChangePassword extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        responseText:"",
        newPassword:"",
        newPasswordConfirm:"",
        passwordChanged:false
      }
    }

componentDidMount() {
    //this.readAuthCodeLocalStorage();
  }

  handlePasswordChange = (event) => {
      this.setState({newPassword:event.target.value});
  }

  handlePasswordConfirmChange = (event) => {
      this.setState({newPasswordConfirm:event.target.value});
  }


 setChangePassword = () => {
   if(this.state.newPassword.length > 0 && this.state.newPasswordConfirm.length > 0 && this.state.newPassword === this.state.newPasswordConfirm){
      this.setState({ isLoading:true, error:false, responseText: ""});
      let url = getServerUrl() + "changepassword?auth_token=" + this.props.auth_token + "&new_password=" +this.state.newPassword; 
       fetch(url)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          if(data === "Password changed!"){
            this.setState({passwordChanged:true});
          }
          this.setState({responseText: data});
       })
       .catch((response) => {
        let responseStatusText = "";
        if(response.statusText === undefined){
          responseStatusText = "No response from server!"
        }
        else{
          responseStatusText = response.statusText;
        }
        this.setState({isLoading: false, error:true, responseText: responseStatusText});
      });
   }
   else{
    this.setState({isLoading: false, error:true, responseText: "Passwords does not match!"});
   }
   

  }


  render() {

    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    let inputFieldBackgroundColor = this.props.colorTheme() === "light" ? "#ffffff" : "#505050";

    let changePasswordButton = <></>;
    if(this.state.newPassword.length > 0 && this.state.newPasswordConfirm.length > 0){
      changePasswordButton = <button style={{color:textColor, borderColor:textColor}} type="button" className="btn btn-outline-secondary btn-block mb-4" onClick={() => this.setChangePassword()}>Change password</button>;
    }

    let responseText = <></>;
    if(this.state.passwordChanged){
      responseText = <p className="text-success">{this.state.responseText}</p>
    }
    else{
      responseText = <p className="text-danger">{this.state.responseText}</p>
    }

    let passwordForm = <></>;
    if(!this.state.passwordChanged){
      passwordForm = <form>
                      <div className="form-outline mb-4">
                        <label style={{color:textColor}} className="form-label" htmlFor="formNewPassword">New Password:</label>
                        <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="password" id="formNewPassword" onChange={(event) => this.handlePasswordChange(event)} className="form-control" value={this.state.newPassword}/>
                      </div>

                      <div className="form-outline mb-4">
                        <label style={{color:textColor}} className="form-label" htmlFor="formNewPasswordConfirm">Confirm Password:</label>
                        <input style={{backgroundColor:inputFieldBackgroundColor, color:textColor}} type="password" id="formNewPasswordConfirm" onChange={(event) => this.handlePasswordConfirmChange(event)} className="form-control" value={this.state.newPasswordConfirm}/>
                      </div>

                      <div className="text-center">
                        {changePasswordButton}
                      </div>
                    </form>;
    }

    return(
      <div>

        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Change password </h5>
            </div>

            <div className="row justify-content-md-center">
              <div className="col-md-auto">

                  <div className="row my-2">
                    <div className="text-center">
                      {responseText}
                    </div>
                  </div>

                  {passwordForm}

              </div>
            </div>

          </div>
        </div>
        

      </div>
    )
  }

}
