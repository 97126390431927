import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

export default class AquasenseorH2SModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        dragOffsets:null
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.setModalDimensions()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.outerWidth < 480){
      width = parseInt(window.outerWidth*0.8) + "px";
    }
    let height = "300px";
    if(window.innerHeight < 480){
      height = parseInt(window.innerHeight*0.8) + "px";
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({showNumericFieldModal:false});
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let co2PPMPv = "No data!";
    let co2PPMOffsetPv = "No data!";

    let o2PercentPv = "No data!";
    let o2PercentFactorPv = "No data!";

    let h2sPPBPv = "No data!";
    let h2sPPBOffsetPv = "No data!";

    let heartBeatPv = "No data!";
    let errorCodePv = "No data!";

    if(this.props.data !== undefined){
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/co2_ppm")){
        co2PPMPv = this.props.data[this.props.dataPath + "/co2_ppm"].value;
        co2PPMPv = parseFloat(parseFloat(co2PPMPv).toFixed(1)) + " ppm";
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/co2_ppm/offset")){
        co2PPMOffsetPv = this.props.data[this.props.dataPath + "/co2_ppm/offset"].value;
        co2PPMOffsetPv = parseFloat(parseFloat(co2PPMOffsetPv).toFixed(1)) + " ppm";
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/o2_percent")){
        o2PercentPv = this.props.data[this.props.dataPath + "/o2_percent"].value;
        o2PercentPv = parseFloat(parseFloat(o2PercentPv).toFixed(1)) + " %";
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/o2_percent/verification_factor")){
        o2PercentFactorPv = this.props.data[this.props.dataPath + "/o2_percent/verification_factor"].value;
        o2PercentFactorPv = parseFloat(parseFloat(o2PercentFactorPv).toFixed(5));
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/h2s_ppb")){
        h2sPPBPv = this.props.data[this.props.dataPath + "/h2s_ppb"].value;
        h2sPPBPv = parseFloat(parseFloat(h2sPPBPv).toFixed(1)) + " ppb";
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/h2s_ppb/offset")){
        h2sPPBOffsetPv = this.props.data[this.props.dataPath + "/h2s_ppb/offset"].value;
        h2sPPBOffsetPv = parseFloat(parseFloat(h2sPPBOffsetPv).toFixed(1)) + " ppb";
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/heartbeat")){
        heartBeatPv = this.props.data[this.props.dataPath + "/heartbeat"].value;
        heartBeatPv = parseFloat(parseFloat(heartBeatPv).toFixed(0));
      }
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/error_code")){
        errorCodePv = this.props.data[this.props.dataPath + "/error_code"].value;
        errorCodePv = parseFloat(parseFloat(errorCodePv).toFixed(0));
      }
    }
 
      
    if(this.props.show){
      return(
      <div>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor, float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.unitName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.customProps.objectName}</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> CO2: {co2PPMPv} </p>
            </div>
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> CO2 offset: {co2PPMOffsetPv} </p>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> O2: {o2PercentPv} </p>
            </div>
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> O2 factor: {o2PercentFactorPv} </p>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> H2S: {h2sPPBPv} </p>
            </div>
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> H2S offset: {h2sPPBOffsetPv} </p>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Heartbeat: {heartBeatPv} </p>
            </div>
            <div className="col-6">
              <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Error code: {errorCodePv} </p>
            </div>
          </div>

        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
