import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class EventLogWindow extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        eventLogDays:1,
        eventLogList:[],
        operatorFilter:""
      }
    }

  componentDidMount() {
    this.getEventLog();
  }

  getEventLog = (days = 1) => {
  if(this.props.auth_token){
    this.setState({ isLoading:true, error:false });
    let url = getServerUrl() + "event_log?auth_token=" + this.props.auth_token;
    url += "&days=" + days;
    fetch(url)
     .then((response) => {
       if(!response.ok) throw new Error(response);
       else return response.json();
     })
     .then((data) => {
       this.setState({ isLoading:false, eventLogList:data });
     })
     .catch((error) => {
       this.setState({ isLoading: false, error:true });
     });
    }
  }

  formatDate = (dateObj) => {
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

  handleeventLogDaysSelect = (event) => {
    this.setState({eventLogDays:event.target.value});
    this.getEventLog(event.target.value);
  }

  handleOperatorFilterSelect = (event) => {
    this.setState({operatorFilter:event.target.value});
    this.getEventLog(this.state.eventLogDays);
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let indexNumber = 0;
    let eventLogDiv = this.state.eventLogList.map((jsonData) => {
                      let eventLogTimeUTC = jsonData.event_time_utc;
                      let eventLogTime = "";
                      if(eventLogTimeUTC){ // Convert UTC to local time for time alarm active
                        eventLogTime = this.formatDate(new Date(eventLogTimeUTC + " UTC"));
                      }
                      let objectName = jsonData.event_object_name;
                      let objectDescription = jsonData.event_object_description;
                      let eventDescription = jsonData.event_description;
                      let systemName = jsonData.system_name;
                      let operatorName = jsonData.operator_name;
                      let eventFromOperator = jsonData.event_from_operator;
                      let includeObject = false;
                      if(this.state.operatorFilter.toLowerCase() === "all" || !this.state.operatorFilter){
                        includeObject = true;
                      }
                      else if(this.state.operatorFilter.toLowerCase() === "operators" && eventFromOperator){
                        includeObject = true;
                      }
                      else if(this.state.operatorFilter.toLowerCase() === "control systems" && !eventFromOperator){
                        includeObject = true;
                      }

                      if(includeObject){
                      indexNumber += 1;
                      let uniqueKey = eventLogTime + objectName + objectDescription + eventDescription + indexNumber;
                      return <tr key={uniqueKey}>
                              <td style={{color:textColor}}>{eventLogTime}</td>
                              <td style={{color:textColor}}>{systemName}</td>
                              <td style={{color:textColor}}>{objectName}</td>
                              <td style={{color:textColor}}>{objectDescription}</td>
                              <td style={{color:textColor}}>{eventDescription}</td>
                              <td style={{color:textColor}}>{operatorName}</td>
                              </tr>
                      }
                      else{
                        return <></>
                      }
    });

    let numberOfEventLog = eventLogDiv.length
    
    return(
      <div style={{width:"inherit",height:"inherit", backgroundColor: backgroundColor}}>

 
        <div className="container-fluid">

          <div className="row">
            <div className="col-12 text-center">
                <h3 style={{color:textColor}}> Event log </h3>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-4">
              <div>
                <select style={{minWidth:"80px", maxWidth:"80px", backgroundColor: backgroundColor, color:textColor}} value={this.state.eventLogDays} onChange={(event) => this.handleeventLogDaysSelect(event)} className="form-select">
                    <option value="1">1d</option>
                    <option value="7">7d</option>
                    <option value="30">30d</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex justify-content-center">
                <select style={{minWidth:"180px", maxWidth:"180px", backgroundColor: backgroundColor, color:textColor}} value={this.state.operatorFilter} onChange={(event) => this.handleOperatorFilterSelect(event)} className="form-select">
                    <option value="All">All</option>
                    <option value="Operators">Operators</option>
                    <option value="Control systems">Control systems</option>
                </select>
              </div>  
            </div>
            <div className="col-4 text-end">
                <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Total events: {numberOfEventLog}</p>
            </div>
          </div>

          
          <div className="table-responsive" style={{maxHeight:"70vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">Event time</th>
                  <th style={{color:textColor}} scope="col">System name</th>
                  <th style={{color:textColor}} scope="col">Object name</th>
                  <th style={{color:textColor}} scope="col">Object description</th>
                  <th style={{color:textColor}} scope="col">Event description</th>
                  <th style={{color:textColor}} scope="col">Operator name</th>
                </tr>
              </thead>
              <tbody>

              {eventLogDiv}

              </tbody>
            </table>
          </div> 

        </div> {/* container-fluid */}
      </div>
    )
  }

}
