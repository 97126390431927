import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class NumericEntryFieldModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        modalWidth:300,
        modalHeight:400,
        newValue:"",
        responseText:"",
        valueChangeSuccess:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        isDragging:false,
        dragCursorOffset:null
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Update the 'new value' each time the current value is updated
    // Can either be from setting a new value or opening this modal with another parameter.
    if(this.props.data.currentValue !== previousProps.data.currentValue){
      this.setState({newValue:this.props.data.currentValue});
    }
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.resetWindowToCenter()
    }
  }


 setModalDimensions = () => {
    let width = "250px";
    if(window.innerWidth < 767){
      width = window.innerWidth*0.6; // 80 vw
    }
    let height = "330px";
    if(window.innerHeight < 767){
      height = window.innerHeight*0.65; // 80 vh
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter()
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  insertEvent = (eventDescription) => {
    let objectName = this.props.data.objectName;
    let objectDescription = this.props.data.objectDescription;
    let systemName = this.props.data.unitName;
    let userName = this.props.data.userName;
    if(eventDescription && objectName && objectDescription && systemName && userName){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "event_object_name": objectName,
          "event_object_description":objectDescription,
          "event_description":eventDescription,
          "system_name":systemName.toUpperCase(),
          "operator_name":userName,
          "event_from_operator":true})};
      let url = getServerUrl() + "insertevent?auth_token=" + this.props.auth_token;
      fetch(url, requestOptions)
       .then((response) => {
        if(response.ok){
          return response.json()
        }
        return Promise.reject(response);
       })
       .then((data) => {
          // pass
       })
       .catch((response) => {
        this.setState({isLoading: false, error:true});
      });
    }
  }

  handleChangeNewValue = () => {
      let unitOfMeasurement = "";
      if(this.props.data.unitOfMeasurement !== undefined){
        unitOfMeasurement = this.props.data.unitOfMeasurement;
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          "topic": this.props.data.dataPath,
          "payload":{"value":this.state.newValue}})
      };
    let url = getServerUrl() + "sendvalue?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
        this.setState({responseText: "Value changed!",valueChangeSuccess:true});
        setTimeout(this.closeModal, 2000);
        let eventText = "Value change from: " + this.props.data.currentValue + " " + unitOfMeasurement +  " to " + this.state.newValue + " " + unitOfMeasurement;
        this.insertEvent(eventText);
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true, responseText: "Error updating value!"});
    });
  }

  closeModal = () => {
   this.props.close(false);
   /* Reset value if window is closed */
   if(this.props.data.currentValue !== undefined){
      this.setState({newValue:this.props.data.currentValue, valueChangeSuccess: false, responseText:""})
    }
  }

  handleNewValueOnChange = (event) => {
    let inputValue = event.target.value;
    let inputValid = true;
    if(this.props.data.min !== undefined){
      if(inputValue < this.props.data.min){
        inputValid = false;
      }
    }
    if(this.props.data.max !== undefined){
      if(inputValue > this.props.data.max){
        inputValid = false;
      }
    }
    if(inputValid){
      this.setState({newValue:inputValue});
    }
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"2",
        padding:"5px"
    };

    let currentValue = this.props.data.currentValue + " " + this.props.data.unitOfMeasurement;

    let responseTextClass = "text-center text-success";
    if(!this.state.valueChangeSuccess && this.state.responseText.length > 0){
      responseTextClass = "text-center text-danger";
    }

    let applyButton = <button disabled type="button" className="btn btn-secondary btn-block mb-4">Apply</button>;
    if(this.props.data.currentValue !== this.state.newValue){
      applyButton = <button type="button" className="btn btn-primary btn-block mb-4" onClick={() => this.handleChangeNewValue()}>Apply</button>;
    }

    let unitName = "";
    if(this.props.data.unitName !== undefined){
      unitName = this.props.data.unitName;
    }

    let newValueStepIncrement = 0.1;
    if(this.props.data.stepIncrement !== undefined){
      newValueStepIncrement = this.props.data.stepIncrement;
    }

    if(this.props.show){
      return(
      <div style={modalStyle}>

        <div className="container-fluid">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor,float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <p style={{margin:0, color:textColor}}>{unitName.toUpperCase()}</p>
                <p style={{margin:0, color:textColor}}><small>{this.props.data.objectName} {this.props.data.objectDescription}</small></p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className={responseTextClass}>
                <h5>{this.state.responseText}</h5>
              </div>
            </div>
          </div>
          <div>

            <form>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-outline mb-1">
                    <label style={{color:textColor}} className="form-label" htmlFor="currentValue">Current value:</label>
                    <input style={{backgroundColor:backgroundColor, color:textColor}} type="text" id="currentValue" className="form-control" value={currentValue} readOnly/>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
              <div className="form-outline mb-2">
                <label style={{color:textColor}} className="form-label" htmlFor="inputNewValue">New value:</label>
                <input style={{backgroundColor:backgroundColor, color:textColor}} type="number" step={newValueStepIncrement} id="inputNewValue" className="form-control" onChange={(event) => this.handleNewValueOnChange(event)}value={this.state.newValue}/>
              </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
              <div className="text-center">
                {applyButton}
              </div>

               </div>
              </div>
            </div>
          </form>
          </div>
          
        </div> {/* container */}

      </div> 
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
