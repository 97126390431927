import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import CreateNewAlarmModal from '../modal/CreateNewAlarmModal'
import RestoreBackupConfigModal from '../modal/RestoreBackupConfigModal'

import {getServerUrl} from '../ApplicationNetworking'

export default class AlarmConfig extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        showCreateNewAlarmModal:false,
        alarmConfig:{},
        filter:"",
        backupAlarmConfigCompleted:false,
        showRestoreAlarmConfigModal:false,
      }
    }

componentDidMount() {
    this.getAlarmConfig();
  }

 getAlarmConfig = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "allalarmconfig?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, alarmConfig:data});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  downloadAlarmConfigBackup = () => {
    if(this.state.alarmConfig){
      try{
      let data = JSON.parse(this.state.alarmConfig);
       const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data, undefined, 2) // pretty print, spacing level = 2
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "alarm-config.json";

      link.click();
      this.setState({backupAlarmConfigCompleted:true});
      }
      catch(error){
        // pass
      }
    }
  }

  openCreateNewAlarmModal = (dataPath) => {
    this.setState({showCreateNewAlarmModal:true});
  }

  closeCreateNewAlarmModal = () => {
    this.setState({showCreateNewAlarmModal:false});
  }

  openRestoreAlarmConfigModal = (dataPath) => {
    this.setState({showRestoreAlarmConfigModal:true});
  }

  closeRestoreAlarmConfigModal = () => {
    this.setState({showRestoreAlarmConfigModal:false});
  }

  confirmDeleteAlarm = (mqttTopic, alarmName, alarmLabel, systemName) => {
    let confirmDelete = window.confirm("Delete alarm: " + mqttTopic + " - " + alarmName + "?");
    if(confirmDelete){
      this.deleteAlarm(mqttTopic, alarmName, alarmLabel, systemName);
    }
  }

  deleteAlarm = (mqttTopic, alarmName, alarmLabel, systemName) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "mqtt_topic": mqttTopic,
        "alarm_name":alarmName,
        "alarm_label":alarmLabel,
        "system_name":systemName})};
    let url = getServerUrl() + "deletealarmconfig?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
      this.getAlarmConfig();
        // pass
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true});
    });
  }

  handleFilterChange = (event) => {
    this.setState({filter:event.target.value});
  }

  setVisibleBasedOnRole = (requiredRole) => {
    let role = this.props.userRole;
    if(role !== undefined){
      role = role.toLowerCase();
      requiredRole = requiredRole.toLowerCase();
      if(requiredRole === "administrator"){
        if(role === "administrator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else if(requiredRole === "operator"){
        if(role === "administrator" || role === "operator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else{
        return "visible";
      }
    }
    else{
      return "invisible";
    }
  }

  handleAlarmPriorityChange = (event, mqttTopic, systemName, alarmLabel, alarmName) => {
    let alarmPriority = event.target.value;
    if(this.props.auth_token && alarmPriority.length > 0 && mqttTopic.length > 0 && alarmLabel.length > 0 && alarmName.length > 0 && systemName.length > 0){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "changealarmpriority?auth_token=" + this.props.auth_token;
     url += "&mqtt_topic=" + mqttTopic;
     url += "&system_name=" + systemName;
     url += "&alarm_label=" + alarmLabel;
     url += "&alarm_name=" + alarmName;
     url += "&alarm_priority=" + alarmPriority;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {;
          this.getAlarmConfig(); // Update alarm config. after alarm priority change.
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  setPrioritySelectionDisabled = () => {
    let role = this.props.userRole;
    let disabled = true;
    if(role !== undefined){
      role = role.toLowerCase();
      if(role === "operator" || role === "administrator"){
        return false
      }
    }
    return disabled;
  }


  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let alarmConfig = {};
    try{
      alarmConfig = JSON.parse(this.state.alarmConfig);
    }
    catch(error){
      // pass
    }

    let alarmConfigListObjects = [];

    Object.keys(alarmConfig).forEach(mqttTopic => {
      Object.keys(alarmConfig[mqttTopic]).forEach(alarmName => {
        let includeObject = false;
        if(this.state.filter.length > 0){
          if(mqttTopic.includes(this.state.filter)){
            includeObject = true;
          }
        }
        else{
          includeObject = true;
        }
      
        if(includeObject){
          let systemName = alarmConfig[mqttTopic][alarmName].system_name;
          let alarmLabel = alarmConfig[mqttTopic][alarmName].alarm_label;
          let alarmPriority = alarmConfig[mqttTopic][alarmName].alarm_priority;
          let alarmValue = alarmConfig[mqttTopic][alarmName].alarm_value;
          if(typeof alarmValue === "boolean"){
            alarmValue = alarmValue ? "true" : "false";
          }
          let alarmValueBitNumber = alarmConfig[mqttTopic][alarmName].alarm_value_bit_number;
          let uniqueKey = mqttTopic + alarmName;
          let object = <tr key={uniqueKey}>
                         <td style={{color:textColor}}>{systemName}</td>
                         <td style={{color:textColor}}>{alarmName}</td>
                         <td style={{color:textColor}}>{alarmLabel}</td>
                         <td>
                            <div><select style={{minWidth:"110px",backgroundColor: backgroundColor, color:textColor}} value={alarmPriority} onChange={(event) => this.handleAlarmPriorityChange(event, mqttTopic, systemName, alarmLabel, alarmName)} disabled={this.setPrioritySelectionDisabled()} className="form-select">
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                            <option value="Critical">Critical</option>
                            </select></div>
                         </td>
                         <td style={{color:textColor}}>{mqttTopic}</td>
                         <td style={{color:textColor}}>{alarmValue}</td>
                         <td style={{color:textColor}}>{alarmValueBitNumber}</td>
                         <td className="text-center"> <FontAwesomeIcon className={this.setVisibleBasedOnRole("administrator")} onClick={() => this.confirmDeleteAlarm(mqttTopic, alarmName, alarmLabel, systemName)}style={{marginRight:"10px", color:textColor,cursor:"pointer"}} size="lg" icon={faTrash}/> </td>
                      </tr>
          alarmConfigListObjects.push(object) 
        }

      });
    })
    
    return(
      <div>

      <CreateNewAlarmModal colorTheme={this.props.colorTheme} update={this.getAlarmConfig} auth_token={this.props.auth_token} show={this.state.showCreateNewAlarmModal} close={this.closeCreateNewAlarmModal} />
      <RestoreBackupConfigModal colorTheme={this.props.colorTheme} title="Restore alarm configuration:" restAPIURL="alarmconfigrestorefrombackup" update={this.getAlarmConfig} auth_token={this.props.auth_token} show={this.state.showRestoreAlarmConfigModal} close={this.closeRestoreAlarmConfigModal} />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Alarm configuration </h5>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-6">
                <label style={{color:textColor}} className="form-label" htmlFor="alarmConfigFilterInput">Filter:</label>
                <input style={{backgroundColor:backgroundColor, color:textColor}} autoComplete="off" placeholder="Type to filter based on mqtt topic.." type="text" id="alarmConfigFilterInput" onChange={(event) => this.handleFilterChange(event)} className="form-control w-50" value={this.state.filter}/>
            </div>

            <div className="col-6">
              <div className="btn-group float-end">
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.openRestoreAlarmConfigModal()} className={this.state.backupAlarmConfigCompleted ? "btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")  : "btn btn-outline-dark invisible"}>Restore from backup</button>
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.downloadAlarmConfigBackup()} className={"btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")}>Backup alarm config.</button>
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.openCreateNewAlarmModal()} className={"btn btn-outline-secondary " + this.setVisibleBasedOnRole("administrator")}>Create new alarm</button>
              </div>
            </div>
          </div>

          <div className="table-responsive" style={{maxHeight:"50vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr >
                  <th style={{color:textColor}} scope="col">System name</th>
                  <th style={{color:textColor}} scope="col">Alarm name</th>
                  <th style={{color:textColor}} scope="col">Alarm label</th>
                  <th style={{color:textColor}} scope="col">Alarm priority</th>
                  <th style={{color:textColor}} scope="col">MQTT topic</th>
                  <th style={{color:textColor}} scope="col">Alarm value</th>
                  <th style={{color:textColor}} scope="col">Alarm value bit no</th>
                  <th style={{color:textColor}} scope="col"></th>
                </tr>
              </thead>
              <tbody>

              {alarmConfigListObjects}

              </tbody>
            </table>
          </div>


        </div>
        

      </div>
    )
  }

}
