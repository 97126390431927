import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faPencil } from '@fortawesome/free-solid-svg-icons'

import NumericEntryFieldModal from './NumericEntryFieldModal'

export default class PIDModal extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        positionOffsetX:window.innerWidth/2,
        positionOffsetY:window.innerHeight/2,
        modalWidth:0,
        modalHeight:0,
        isDragging:false,
        showNumericFieldModal:false,
        numericFieldModalData:{},
        dragOffsets:null,
        editUnlocked:false
      }
  }

  componentDidMount() {
    this.setModalDimensions();
    window.addEventListener('resize', this.setModalDimensions);
  }

  componentDidUpdate(previousProps) {
    // Reset the window when its closed so it will be centered at the screen once its opens again.
    if(previousProps.show && !this.props.show){
      this.setModalDimensions()
    }
  }

  setModalDimensions = () => {
    let width = "380px";
    if(window.outerWidth < 480){
      width = parseInt(window.outerWidth*0.8) + "px";
    }
    let height = "250px";
    if(window.innerHeight < 480){
      height = parseInt(window.innerHeight*0.8) + "px";
    }
    this.setState({ modalWidth: width, modalHeight:height});
    this.resetWindowToCenter();
  }

  resetWindowToCenter = () => {
    this.setState({positionOffsetX:window.innerWidth/2, positionOffsetY:window.innerHeight/2});
  }

  closeModal = () => {
    this.props.close(false);
    this.setState({showNumericFieldModal:false});
  }

  openNumericFieldModal = (jsonData) => {
    if(this.state.editUnlocked){
      this.setState({showNumericFieldModal:true, numericFieldModalData:jsonData});
    }
  }

  closeNumericFieldModal = () => {
    this.setState({showNumericFieldModal:false});
  }

  dragWindow = (event) => {
    event.preventDefault();
    let cursorOffsetX = 0;
    let cursorOffsetY = 0;
    if(!this.state.dragCursorOffset){
      this.setState({dragCursorOffset:[event.clientX - this.state.positionOffsetX,event.clientY - this.state.positionOffsetY]});
      cursorOffsetX = event.clientX - this.state.positionOffsetX;
      cursorOffsetY = event.clientY - this.state.positionOffsetY;
    }
    else{
      cursorOffsetX = this.state.dragCursorOffset[0];
      cursorOffsetY = this.state.dragCursorOffset[1];  
    }
    if(event.clientX > 0 &&  event.clientY > 0){ 

      let newPosX = event.clientX - cursorOffsetX;
      // Limit X position inside window.
      if(newPosX > window.innerWidth - this.state.modalWidth/2){
        newPosX = window.innerWidth - this.state.modalWidth/2;
      }
      else if (newPosX < this.state.modalWidth/2){
        newPosX = this.state.modalWidth/2;
      }
      let newPosY = event.clientY - cursorOffsetY
      // Limit Y position inside window.
      if(newPosY > window.innerHeight - this.state.modalHeight/2){
        newPosY = window.innerHeight - this.state.modalHeight/2;
      }
      else if (newPosY < this.state.modalHeight/2){
        newPosY = this.state.modalHeight/2;
      }
      this.setState({positionOffsetX:newPosX, positionOffsetY:newPosY, isDragging:true});
    }
  }

  resetIsDragging = () =>{
    this.setState({isDragging:false, dragCursorOffset:null});
  }

  toggleEditUnlock = () => {
    this.setState({editUnlocked:!this.state.editUnlocked});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let modalStyle = {
        position:"absolute",
        height: this.state.modalHeight,
        width: this.state.modalWidth,
        left: this.state.positionOffsetX,
        top: this.state.positionOffsetY,
        transform: "translate(-50%, -50%)",
        backgroundColor: backgroundColor,
        border: this.props.colorTheme() === "light" ? "5px solid #053d57" : "2px solid #f2f2f2",
        borderRadius: "10px",
        zIndex:"1",
        padding:"5px"
    };

    let styleParameters = {
          fontSize:"calc(50% + 0.6vw)",
          width:"100%", 
          height:"auto",
          marginLeft:"15px",
          color:textColor
    };

    let editLockSymbol = this.state.editUnlocked ? faLockOpen : faLock;

    let localSPDiv = <div className="border rounded">
                  <i style={styleParameters} >Local setpoint: No data! </i>
                </div>;
    let localSPPv = NaN;
    let localSPEngUnit = "";

    let outputMinDiv = <div className="border rounded">
                  <i style={styleParameters} >Output minimum: No data! </i>
                </div>;
    let outputMinPv = NaN;
    let outputMinEngUnit = "";

    let outputMaxDiv = <div className="border rounded">
                  <i style={styleParameters} >Output maximum: No data! </i>
                </div>;
    let outputMaxPv = NaN;
    let outputMaxEngUnit = "";

    if(this.props.data !== undefined){
      if(this.props.data.hasOwnProperty(this.props.dataPath + "/local_setpoint")){
        localSPPv = this.props.data[this.props.dataPath + "/local_setpoint"].value;
        localSPEngUnit = this.props.data[this.props.dataPath + "/local_setpoint"].unit_of_measurement;
        localSPDiv = <div className="border rounded">
                  <i style={styleParameters} >Local setpoint: {localSPPv} {localSPEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/local_setpoint/set","currentValue":localSPPv,"unitOfMeasurement":localSPEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", local setpoint",
                     "min":this.props.customProps.pvMin, "max":this.props.customProps.pvMax, "unitName":this.props.unitName, "userName":this.props.customProps.userName, 
                     "stepIncrement":this.props.customProps.stepIncrement})}/>
                  </i>
                </div>;
      }


      if(this.props.data.hasOwnProperty(this.props.dataPath + "/output_min")){
        outputMinPv = this.props.data[this.props.dataPath + "/output_min"].value;
        outputMinEngUnit = this.props.data[this.props.dataPath + "/output_min"].unit_of_measurement;
        outputMinDiv = <div className="border rounded">
                  <i style={styleParameters} >Output minimum: {outputMinPv} {outputMinEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/output_min/set","currentValue":outputMinPv,"unitOfMeasurement":outputMinEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", output minimum",
                     "min":0.0, "max":100.0, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }

      if(this.props.data.hasOwnProperty(this.props.dataPath + "/output_max")){
        outputMaxPv = this.props.data[this.props.dataPath + "/output_max"].value;
        outputMaxEngUnit = this.props.data[this.props.dataPath + "/output_max"].unit_of_measurement;
        outputMaxDiv = <div className="border rounded">
                  <i style={styleParameters} >Output maximum: {outputMaxPv} {outputMaxEngUnit} 
                    <FontAwesomeIcon className="float-end" style={{cursor:"pointer", marginRight:"10px"}} icon={faPencil} onClick={() => this.openNumericFieldModal(
                    {"dataPath":this.props.dataPath+"/output_max/set","currentValue":outputMaxPv,"unitOfMeasurement":outputMaxEngUnit, 
                     "objectName":this.props.customProps.objectName, "objectDescription":this.props.customProps.objectDescription + ", output maximum",
                     "min":0.0, "max":100.0, "unitName":this.props.unitName, "userName":this.props.customProps.userName})}/>
                  </i>
                </div>;
      }
    }  
      
    if(this.props.show){
      return(
      <div>
      <NumericEntryFieldModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} show={this.state.showNumericFieldModal} close={this.closeNumericFieldModal} data={this.state.numericFieldModalData}/>

      <div style={modalStyle}>

        <div className="container">

          <div draggable="true" style={{cursor:"move"}} onDrag={(event) => this.dragWindow(event)} onDragEnd={this.resetIsDragging} className="row">
            <div className="col-sm-12">
              <button className="close-button" style={{backgroundColor:backgroundColor, float:"right", borderRadius:"5px", borderColor:textColor}} onClick={() => this.closeModal()}>
                <span style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} aria-hidden="true">&times;</span>
              </button>
              <FontAwesomeIcon onClick={() => this.toggleEditUnlock()} className="float-start" size="2x" style={{cursor:"pointer", marginRight:"10px", color:textColor}} icon={editLockSymbol} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.unitName.toUpperCase()}</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h5 style={{color:textColor}}>{this.props.customProps.objectName} - {this.props.customProps.objectDescription}</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {localSPDiv}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 my-1">
              {outputMinDiv}
            </div>
          </div> 
          
          <div className="row">
            <div className="col-sm-12 my-1">
              {outputMaxDiv}
            </div>
          </div>

        </div> {/* container */}

      </div> 
      </div>
    )}
    else{
      return(
        <div></div>
      )
    }
  }

}
