import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import CreateNewDataSubModal from '../modal/CreateNewDataSubModal'

import {getServerUrl} from '../ApplicationNetworking'

export default class DataConfig extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        dataConfig:{},
        showCreateNewSubModal:false
      }
    }

componentDidMount() {
    this.getDataConfig();
  }

 getDataConfig = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "alldataconfig?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, dataConfig:data});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  openCreateNewSubModal = (dataPath) => {
    this.setState({showCreateNewSubModal:true});
  }

  closeCreateNewSubModal = () => {
    this.setState({showCreateNewSubModal:false});
  }

  confirmDeleteDataSub = (topic) => {
    let confirmDelete = window.confirm("Delete data sub. " + topic + "?");
    if(confirmDelete){
      this.deleteDataSub(topic);
      console.log("Delte data sub: " + topic);
    }
  }

 deleteDataSub = (topic) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "mqtt_topic": topic})};
    let url = getServerUrl() + "deletedataconfig?auth_token=" + this.props.auth_token;
    fetch(url, requestOptions)
     .then((response) => {
      if(response.ok){
        return response.json()
      }
      return Promise.reject(response);
     })
     .then((data) => {
      this.getDataConfig();
        // pass
     })
     .catch((response) => {
      this.setState({isLoading: false, error:true});
    });
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let dataConfig = {};
    try{
      dataConfig = JSON.parse(this.state.dataConfig);
    }
    catch(error){
      // pass
    }
    
    return(
      <div>

      <CreateNewDataSubModal colorTheme={this.props.colorTheme} update={this.getDataConfig} auth_token={this.props.auth_token} show={this.state.showCreateNewSubModal} close={this.closeCreateNewSubModal} />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Data subscriptions </h5>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-12">
                <button type="button" style={{color:textColor, borderColor:textColor}} onClick={() => this.openCreateNewSubModal()} className="btn btn-outline-secondary float-end">Create new data sub.</button>
            </div>
          </div>

          <div className="table-responsive" style={{maxHeight:"50vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">System name:</th>
                  <th style={{color:textColor}} scope="col">MQTT topic:</th>
                  <th style={{color:textColor}} scope="col">MQTT QOS:</th>
                  <th style={{color:textColor}} scope="col"></th>
                </tr>
              </thead>
              <tbody>
              {Object.keys(dataConfig).map((jsonData) =>(
                <tr key={jsonData}>
                  <td style={{color:textColor}}>{dataConfig[jsonData]["system_name"]}</td>
                  <td style={{color:textColor}}>{jsonData}</td>
                  <td style={{color:textColor}}>{dataConfig[jsonData]["mqtt_qos"]}</td>
                  <td className="text-center"> <FontAwesomeIcon onClick={() => this.confirmDeleteDataSub(jsonData)}style={{marginRight:"10px", color:textColor,cursor:"pointer"}} size="lg" icon={faTrash}/> </td>
                </tr>
            ))}

              </tbody>
            </table>
          </div>

        </div>
        

      </div>
    )
  }

}
