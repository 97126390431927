import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgAnalog from '../svg/SvgAnalog';

export default class AquasenseOverView extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

  jsonGet(jsondata, key){
      try{
        return jsondata[key];
      }catch(error){
        //console.error(error);
        return {};
      }
    }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }


  render() {
    let aqs0685ComError = this.props.listComErrorSystem.includes("aqs-06-85");
    let aqs0686ComError = this.props.listComErrorSystem.includes("aqs-06-86");
    let aqs0687ComError = this.props.listComErrorSystem.includes("aqs-06-87");
    let aqs0688ComError = this.props.listComErrorSystem.includes("aqs-06-88");
    let aqs0689ComError = this.props.listComErrorSystem.includes("aqs-06-89");
    let aqs0685redCrossWhenComError = <></>;
    if(aqs0685ComError){
      aqs0685redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M125 265 L500 605 M125 605 L500 265"/>
    }
    let aqs0686redCrossWhenComError = <></>;
    if(aqs0686ComError){
      aqs0686redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M555 265 L930 605 M555 605 L930 265"/>
    }
    let aqs0687redCrossWhenComError = <></>;
    if(aqs0687ComError){
      aqs0687redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M985 265 L1360 605 M985 605 L1360 265"/>
    }
    let aqs0688redCrossWhenComError = <></>;
    if(aqs0688ComError){
      aqs0688redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M125 665 L500 905 M125 905 L500 665"/>
    }
    let aqs0689redCrossWhenComError = <></>;
    if(aqs0689ComError){
      aqs0689redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1415 265 L1790 605 M1415 605 L1790 265"/>
    }


    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    return(

      <div style={{height:"100%", width:"100%",backgroundColor:backgroundColor}}>

        <div style={{width:"100%", height:"100%"}}>
         <svg  style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >Overview</text>
            <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>

            {/* Border for AQS-06-85 */} 
            <text x="312.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-85</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="260" width="385" height="350" ry="20"/>
  
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={185} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={310} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={435} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={247.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={372.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={185} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={310} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={435} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-85/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  

            {aqs0685redCrossWhenComError}

            {/* Border for AQS-06-86*/}

            <text x="742.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-86</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="550" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={615} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={740} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={865} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={677.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={802.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={615} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={740} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={865} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-86/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  

            {aqs0686redCrossWhenComError}

            {/* AQS-06-87 */}

            <text x="1172.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-87</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="980" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1045} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1170} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1295} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1107.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1232.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1045} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1170} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1295} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-87/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            {aqs0687redCrossWhenComError}


            {/* AQS-06-88 */}

            <text x="312.5" y="645" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-88</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="660" width="385" height="250" ry="20"/>

            <SvgAnalog objectName="O2" objectDescription="O2 kg/h" pvMin={0.0} pvMax={150.0} x={185} y={810} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-88/analog/o2_kgh"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 kg/h" pvMin={0.0} pvMax={50.0} x={310} y={810} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-88/analog/co2_kgh"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S mg/h" pvMin={0.0} pvMax={50.0} x={435} y={810} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-88/analog/h2s_mgh"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="FT1" objectDescription="Air velocity" pvMin={0.0} pvMax={50.0} x={247.5} y={900} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-88/analog/air_speed"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Air temperature" pvMin={0.0} pvMax={50.0} x={372.5} y={900} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-88/analog/air_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

            {aqs0688redCrossWhenComError}

            {/* AQS-06-89 */}

            <text x="1602.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-89</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="1410" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1475} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1600} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1725} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1537.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1662.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1475} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1600} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1725} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-89/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            
            {aqs0689redCrossWhenComError}

          </svg>
        </div>
 
      </div>
    )

    }

}
