import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import {getServerUrl} from '../ApplicationNetworking'

export default class Users extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        users:[]
      }
    }

componentDidMount() {
    this.getUsers();
}

confirmDeleteUser = (username) => {
  let confirmDelete = window.confirm("Delete user " + username + "?");
  if(confirmDelete){
    this.deleteUser(username);
  }
}

deleteButtonClassName = (username) => {
  if(username === this.props.userName){
    return "btn btn-danger btn-sm invisible"
  }
  else{
    return "btn btn-danger btn-sm"
  }
}

getUsers = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "allusers?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, users:data});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

deleteUser = (username) => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "deleteuser?auth_token=" + this.props.auth_token + "&username=" + username;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
          this.getUsers() // Just update the user list after deletion of a user.
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let userListDiv = <></>;

    userListDiv = this.state.users.map((jsonData) =>{
      let user = <tr key={JSON.stringify(jsonData)}>
                  <td style={{color:textColor}}>{jsonData.username}</td>
                  <td style={{color:textColor}}>{jsonData.role}</td>
                  <td style={{color:textColor}} className="text-center">
                      <FontAwesomeIcon onClick={() => this.confirmDeleteUser(jsonData.username)} data-toggle="confirmation"style={{color:textColor,cursor:"pointer"}} size="lg" icon={faTrash}/> 
                  </td>
                  </tr>
      return user;
    });

    return(
      <div style={{height:"100%",width:"100%"}}>  
          <div className="row">
            <div className="col-12 text-center">
                <h5 style={{color:textColor}}> Users </h5>
            </div>
          </div>

          <div className="table-responsive" style={{border:"3px solid #787878", borderRadius:"5px", maxHeight:"60vh"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">Username</th>
                  <th style={{color:textColor}} scope="col">Role</th>
                  <th style={{color:textColor}} scope="col"></th>
                </tr>
              </thead>
              <tbody>

              {userListDiv}

              </tbody>
            </table>
          </div>

      </div>
    )
  }

}
