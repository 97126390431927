
import React from "react";


export default class SvgMotor extends React.Component {

	prohibitPopUpFunc = () => {
		return false;
	}

	getPolyLineArrow = (isRunning, runningDirection, idleDirection) => {
		let direction = "right";
		if(runningDirection !== undefined && idleDirection !== undefined){
			if(isRunning){
				direction = runningDirection.toLowerCase();
			}
			else{
				direction = idleDirection.toLowerCase();
			}
		}

		direction = direction.toLowerCase();
		if(direction === "up"){
			let pumpArrowUp = (this.props.x + 8) + "," + (this.props.y + 20);
			pumpArrowUp += " " + (this.props.x + 8) + "," + (this.props.y);
			pumpArrowUp += " " + (this.props.x + 20) + "," + (this.props.y);
			pumpArrowUp += " " + (this.props.x) + "," + (this.props.y - 20);
			pumpArrowUp += " " + (this.props.x - 20) + "," + (this.props.y);
			pumpArrowUp += " " + (this.props.x - 8) + "," + (this.props.y);
			pumpArrowUp += " " + (this.props.x - 8) + "," + (this.props.y + 20);
			pumpArrowUp += " " + (this.props.x + 8) + "," + (this.props.y + 20);
			return pumpArrowUp;
		}
		else if(direction === "down"){
			let pumpArrowDown = (this.props.x + 8) + "," + (this.props.y - 20);
			pumpArrowDown += " " + (this.props.x + 8) + "," + (this.props.y);
			pumpArrowDown += " " + (this.props.x + 20) + "," + (this.props.y);
			pumpArrowDown += " " + (this.props.x) + "," + (this.props.y + 20);
			pumpArrowDown += " " + (this.props.x - 20) + "," + (this.props.y);
			pumpArrowDown += " " + (this.props.x - 8) + "," + (this.props.y);
			pumpArrowDown += " " + (this.props.x - 8) + "," + (this.props.y - 20);
			pumpArrowDown += " " + (this.props.x + 8) + "," + (this.props.y - 20);
			return pumpArrowDown;

		}
		else if(direction === "left"){
			let pumpArrowLeft = (this.props.x + 20) + "," + (this.props.y + 8);
			pumpArrowLeft += " " + (this.props.x) + "," + (this.props.y + 8);
			pumpArrowLeft += " " + (this.props.x) + "," + (this.props.y + 20);
			pumpArrowLeft += " " + (this.props.x - 20) + "," + (this.props.y);
			pumpArrowLeft += " " + (this.props.x) + "," + (this.props.y - 20);
			pumpArrowLeft += " " + (this.props.x) + "," + (this.props.y - 8);
			pumpArrowLeft += " " + (this.props.x + 20) + "," + (this.props.y - 8);
			pumpArrowLeft += " " + (this.props.x + 20) + "," + (this.props.y + 8);
			return pumpArrowLeft;
		}
		else{ // direction -> Right
			let pumpArrowRight = (this.props.x - 20) + "," + (this.props.y + 8);
			pumpArrowRight += " " + (this.props.x) + "," + (this.props.y + 8);
			pumpArrowRight += " " + (this.props.x) + "," + (this.props.y + 20);
			pumpArrowRight += " " + (this.props.x + 20) + "," + (this.props.y);
			pumpArrowRight += " " + (this.props.x) + "," + (this.props.y - 20);
			pumpArrowRight += " " + (this.props.x) + "," + (this.props.y - 8);
			pumpArrowRight += " " + (this.props.x - 20) + "," + (this.props.y - 8);
			pumpArrowRight += " " + (this.props.x - 20) + "," + (this.props.y + 8);
			return pumpArrowRight;
		}
	}


	render(){

		let data = {};;
		if(this.props.data !== undefined){
			data = this.props.data;
		}

		let statusRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/status_register")){
			statusRegister = data[this.props.dataPath + "/status_register"].value;
		}

		let alarmRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/alarm_register")){
			alarmRegister = data[this.props.dataPath + "/alarm_register"].value;
		}

		let runningDirection = "right";
		if(this.props.runningDirection !== undefined){
			runningDirection = this.props.runningDirection;
		}
		let idleDirection = "right";
		if(this.props.idleDirection !== undefined){
			idleDirection = this.props.idleDirection;
		}

		/* Pump arrow (running) */


		/* Title text */
		let titlePositionX = this.props.x;
		if(this.props.titlePositionX){
			titlePositionX = this.props.x - this.props.titlePositionX;
		}
		let titlePositionY = this.props.y - 55;
		if(this.props.titlePositionY){
			titlePositionY = this.props.y - this.props.titlePositionY;
		}
		let titleFontSize = 24;
		if(this.props.titleFontSize){
			titleFontSize = this.props.titleFontSize;
		}
		let titleStyle = {
			fontSize:String(titleFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let titleText = this.props.objectName;

		/* Mode text, e.g. M for manual, LM for local manual etc */
		let modePositionX = this.props.x + 35;
		if(this.props.modePositionX){
			modePositionX = this.props.x + this.props.modePositionX;
		}
		let modePositionY = this.props.y + 60;
		if(this.props.modePositionY){
			modePositionY = this.props.y + this.props.modePositionY;
		}
		let modeFontSize = 24;
		if(this.props.modeFontSize){
			modeFontSize = this.props.modeFontSize;
		}
		let modeStyle = {
			fontSize:String(modeFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let modeText = "";
		if(statusRegister & Math.pow(2,1)){
			modeText = "LM";
		}
		else if(statusRegister & Math.pow(2,3)){
			modeText = "M";
		}
		/* Feedback speed text */
		let feedbackSpeedFontSize = 20;
		if(this.props.feedbackSpeedFontSize){
			feedbackSpeedFontSize = this.props.feedbackSpeedFontSize;
		}
		let feedbackSpeedFontStyle = {
			fontSize:String(feedbackSpeedFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let feedbackSpeedPositionX = this.props.x - 25;
		if(this.props.feedbackSpeedPositionX){
			feedbackSpeedPositionX = this.props.x + this.props.feedbackSpeedPositionX;
		}
		let feedbackSpeedPositionY = this.props.y + 58;
		if(this.props.feedbackSpeedPositionY){
			feedbackSpeedPositionY = this.props.y + this.props.feedbackSpeedPositionY;
		}

		let customsProps = {};
		if(this.props.showResetOutput !== undefined){
			customsProps["showResetOutput"] = this.props.showResetOutput;
		}
		if(this.props.showAutoSpeedSetpoint !== undefined){
			customsProps["showAutoSpeedSetpoint"] = this.props.showAutoSpeedSetpoint;
		}
		if(this.props.objectName !== undefined){
			customsProps["objectName"] = this.props.objectName;
		}
		if(this.props.objectDescription !== undefined){
			customsProps["objectDescription"] = this.props.objectDescription;
		}
		if(this.props.userName !== undefined){
			customsProps["userName"] = this.props.userName;
		}

		let modalFunction = this.prohibitPopUpFunc;
		if(typeof this.props.openModal === "function"){
			modalFunction = () => this.props.openModal(this.props.dataPath, customsProps);
		}

    	let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    	let backgroundColor = this.props.colorTheme() === "light" ? "rgb(213,213,213)" : "#323232";
    	let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";

		if(alarmRegister > 0){
			backgroundColor = "rgb(213,0,0)"
		}

		let showFeedbackSpeed = true;
		if(this.props.hideFeedbackSpeed !== undefined){
			if(this.props.hideFeedbackSpeed){
				showFeedbackSpeed = false;
			}
		}
		let feedbackSpeedText = <></>;
		if(showFeedbackSpeed){
			if(data.hasOwnProperty(this.props.dataPath + "/feedback_speed")){
				let feedbackSpeed = parseFloat(data[this.props.dataPath + "/feedback_speed"].value).toFixed(1);
			feedbackSpeedText = <text fill={textColor} x={feedbackSpeedPositionX} y={feedbackSpeedPositionY} style={feedbackSpeedFontStyle} >{feedbackSpeed} %</text>;
			}
		}

		let runningColor = this.props.colorTheme() === "light" ? "rgb(255,255,255)" : "rgb(200,200,200)";
		let isRunning = false;
		if(statusRegister & Math.pow(2,0)){
			runningColor = "rgb(0,213,0)"
			isRunning = true;
		}


		return(
			<>
			<circle fill={backgroundColor} stroke={strokeColor} cx={this.props.x} cy={this.props.y} r="35">
			</circle>
			<polyline fill={runningColor} stroke="rgb(0,0,0)" strokeWidth="1" points={this.getPolyLineArrow(isRunning, runningDirection, idleDirection)} />
			<text fill={textColor} x={titlePositionX} y={titlePositionY} style={titleStyle} >{titleText}</text>
			<text fill={textColor} x={modePositionX} y={modePositionY} style={modeStyle} >{modeText}</text>
			{feedbackSpeedText}
			<rect onClick={modalFunction} fill="transparent" stroke="transparent" x={this.props.x-60} y={this.props.y-80} width="120" height="160" ry="10" />
			</>
		) 
		
	}
}
	
          