import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import DataConfig from './DataConfig'
import AlarmConfig from './AlarmConfig'
import EventConfig from './EventConfig'

export default class DataVaultMain extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        showPage:""
      }
    }

componentDidMount() {
    //
  }

  setShowPage = (pageName) => {
    this.setState({showPage:pageName})
  }

  setButtonDisabled = (currentRole, roleRequired) => {
    if(currentRole.toLowerCase() === roleRequired.toLowerCase()){
      return "btn btn-outline-dark"
    }
    else{
      return "btn btn-outline-dark disabled"
    }
  }

  setVisibleBasedOnRole = (requiredRole) => {
    let role = this.props.userRole;
    if(role !== undefined){
      role = role.toLowerCase();
      requiredRole = requiredRole.toLowerCase();
      if(requiredRole === "administrator"){
        if(role === "administrator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else if(requiredRole === "operator"){
        if(role === "administrator" || role === "operator"){
          return "visible";
        }
        else{
          return "invisible";
        }
      }
      else{
        return "visible";
      }
    }
    else{
      return "invisible";
    }

  }


  render() {

    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let pageSelector = <></>;
    let pageBackBtn = <></>;
    if(this.state.showPage){
      pageBackBtn = <button style={{color:textColor, borderColor:textColor}} type="button" onClick={() => this.setShowPage("")} className="btn btn-outline-secondary">Back</button>;
    }
    if(this.state.showPage === "dataconfig"){
      pageSelector = <DataConfig colorTheme={this.props.colorTheme} userName={this.props.userName} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "alarmconfig"){
      pageSelector = <AlarmConfig colorTheme={this.props.colorTheme} userRole={this.props.userRole} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "eventconfig"){
      pageSelector = <EventConfig colorTheme={this.props.colorTheme} userRole={this.props.userRole} auth_token={this.props.auth_token}/>
    }
    else{
      pageSelector = <div className="container">
                      <div className="row">
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("alarmconfig")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("operator")}>Alarm configuration</button>
                        </div>
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("dataconfig")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("administrator")}>Data configuration</button>
                        </div>
                        <div className="col-4 text-center my-3">
                          <button type="button" style={{width:"100%",height:"100%", color: textColor, borderColor: textColor}} onClick={() => this.setShowPage("eventconfig")} className={"btn btn-outline-secondary btn-sm " + this.setVisibleBasedOnRole("administrator")}>Event configuration</button>
                        </div>
                    </div>
                  </div>;
    }

    return(
      <div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h1 style={{color:textColor}}> Data vault </h1>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding:"2vh"}}>
          <div className="row">
            <div className="col-12">
              {pageBackBtn}
            </div>
          </div>
          <div>
            {pageSelector}
          </div>
        </div>

      </div>
    )
  }

}
