
import React from "react";


export default class SvgPID extends React.Component {

	prohibitPopUpFunc = () => {
		return false;
	}

	render(){

		let data = {};;
		if(this.props.data !== undefined){
			data = this.props.data;
		}


		let commandoRegister = 0;
		if(data.hasOwnProperty(this.props.dataPath + "/commando_register")){
			commandoRegister = data[this.props.dataPath + "/commando_register"].value;
		}
		let currentSetpoint = "";
		if(data.hasOwnProperty(this.props.dataPath + "/local_setpoint")){
			let unit_of_measurement = data[this.props.dataPath + "/local_setpoint"].unit_of_measurement;
			if(unit_of_measurement === "deg.c"){
				unit_of_measurement = "°C";
			}
			else if(unit_of_measurement === "meter"){
				unit_of_measurement = "m";
			}
			currentSetpoint = "SP: " + data[this.props.dataPath + "/local_setpoint"].value;
			currentSetpoint += " " + unit_of_measurement;

		}

		let isAuto = (commandoRegister & Math.pow(2,0)) === Math.pow(2,0);	

		/* Title text */
		let titleFontSize = 24;
		if(this.props.titleFontSize){
			titleFontSize = this.props.titleFontSize;
		}
		let titleStyle = {
			fontSize:String(titleFontSize),
			alignmentBaseline:"middle",
			textAnchor:"middle"
		};
		let titleText = this.props.objectName;	

		let customsProps = {};
		if(this.props.objectName !== undefined){
			customsProps["objectName"] = this.props.objectName;
		}
		if(this.props.objectDescription !== undefined){
			customsProps["objectDescription"] = this.props.objectDescription;
		}
		if(this.props.userName !== undefined){
			customsProps["userName"] = this.props.userName;
		}
		if(this.props.pvMin !== undefined){
			customsProps["pvMin"] = this.props.pvMin;
		}
		if(this.props.pvMax !== undefined){
			customsProps["pvMax"] = this.props.pvMax;
		}
		if(this.props.stepIncrement !== undefined){
			customsProps["stepIncrement"] = this.props.stepIncrement;
		}

		let modalFunction = this.prohibitPopUpFunc;
		if(typeof this.props.openModal === "function"){
			modalFunction = () => this.props.openModal(this.props.dataPath, customsProps);
		}

    	let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    	let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    	let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";

		return(
			<>
			  <rect fill={backgroundColor} stroke={isAuto?"rgb(0,213,0)":strokeColor} strokeWidth={isAuto?"4":"1"} x={this.props.x} y={this.props.y} width="50" height="50" ry="10"/>
          	   <text fill={textColor} x={this.props.x + 25} y={this.props.y + 25} style={titleStyle} >{titleText}</text>
          	   <text fill="rgb(0,100,213)" x={this.props.x + 25} y={this.props.y + 65} style={{fontSize:16, alignmentBaseline:"middle", textAnchor:"middle"}} >{currentSetpoint}</text>
          	  <rect onClick={modalFunction} fill="transparent" stroke="transparent" strokeWidth="1" x={this.props.x - 20} y={this.props.y - 15} width="90" height="90"/>  
			</>
		) 
	}
}
	
          