


export function getServerUrl(){
	if(parseInt(process.env.REACT_APP_PRODUCTION_MODE) === 1){
		return window.location.href;
	}
	else{
		return "http://" + process.env.REACT_APP_DEV_SERVER_ADDRESS + ":" +  process.env.REACT_APP_DEV_SERVER_PORT + "/";
	}
}