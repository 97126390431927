import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgMotor from '../svg/SvgMotor';
import SvgAnalog from '../svg/SvgAnalog';
import SvgValve from '../svg/SvgValve';
import SvgPID from '../svg/SvgPID';

import AnalogModal from '../modal/AnalogModal'
import MotorModal from '../modal/MotorModal'
import ValveModal from '../modal/ValveModal'
import PIDModal from '../modal/PIDModal'
import AquasensorH2SModal from '../modal/AquasensorH2SModal'
import AquasenseVerificationModal from '../modal/AquasenseVerificationModal'

export default class AquasenseWellBoat extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        data:{},
        showAnalogModal:false,
        analogModalDataPath:"",
        showMotorModal:false,
        showValveModal:false,
        showPIDModal:false,
        showAquasensorH2SModal:false,
        showAquasenseVerificationModal:false,
        motorModalDataPath:"",
        valveModalDataPath:"",
        pidModalDataPath:"",
        aquasensorH2SModalDataPath:"",
        aquasenseVerificationModalDataPath:"",
        motorModalCustomProps:{},
        analogModalCustomProps:{},
        valveModalCustomProps:{},
        pidModalCustomProps:{},
        aquasensorH2SModalCustomProps:{},
        aquasenseVerificationModalCustomProps:{}
      }
    }

  jsonGet(jsondata, key){
      try{
        let data = jsondata[key];
        if (typeof data == "object"){
              return data; // JSON
        } else {
              return {} //Not Json
        }
      }catch(error){
          return {};   
      }
  }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

  convertSecondsToHHMMSS = (totalSeconds) => {
    let retValue = "";
    if(!isNaN(totalSeconds)){
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
      if(hours > 0){
        if(hours < 10){
          retValue = "0" + hours + " h "
        }
        else{
          retValue = hours + " h "
        }
      }
      if(minutes > 0){
        if(minutes < 10){
          retValue += "0" + minutes + " m "
        }
        else{
          retValue += minutes + " m "
        }
      }
      if(seconds > 0){
        if(seconds < 10){
          retValue += "0" + seconds + " s "
        }
        else{
          retValue += seconds + " s "
        }
      }
    }
    return retValue
  }

  openAnalogModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAnalogModal:true, analogModalDataPath:dataPath, analogModalCustomProps:customProps});
      }
    }
  }

  closeAnalogModal = () => {
    this.setState({showAnalogModal:false});
  }

  openMotorModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showMotorModal:true, motorModalDataPath:dataPath, motorModalCustomProps: customProps});
      }
    }
  }

  closeMotorModal = () => {
    this.setState({showMotorModal:false});
  }

  openAquasenseVerificationModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAquasenseVerificationModal:true, aquasenseVerificationModalDataPath:dataPath, aquasenseVerificationModalCustomProps: customProps});
      }
    }
  }

  closeAquasenseVerificationModal = () => {
    this.setState({showAquasenseVerificationModal:false});
  }

  openPIDModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showPIDModal:true, pidModalDataPath:dataPath, pidModalCustomProps: customProps});
      }
    }
  }

  closePIDModal = () => {
    this.setState({showPIDModal:false});
  }

  openValveModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showValveModal:true, valveModalDataPath:dataPath, valveModalCustomProps: customProps});
      }
    }
  }

  closeValveModal = () => {
    this.setState({showValveModal:false});
  }

  openAquasensorH2SModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAquasensorH2SModal:true, aquasensorH2SModalDataPath:dataPath, aquasensorH2SModalCustomProps: customProps});
      }
    }
  }

  closeAquasensorH2SModal = () => {
    this.setState({showAquasensorH2SModal:false});
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    let pipeIdleColor = this.props.colorTheme() === "light" ? "rgb(213,213,213)" : "#323232";
    let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";
    let dottedLineColor = this.props.colorTheme() === "light" ? "rgb(80,80,80)" : "rgb(213,213,213)";

    let data = {};
    let systemCommunicationError = false;
    if(this.props.systemCommunicationError !== undefined){
      systemCommunicationError = this.props.systemCommunicationError;
    }
    if(!systemCommunicationError){
      try{
        data = this.props.data;
      }
      catch(error){
        // pass
      }
    }

    let locationName = this.jsonGet(data, this.props.unitName + "/system/location_name")
    if(locationName.hasOwnProperty("value")){
      locationName = locationName.value;
    }
    else{
      locationName = "";
    }

    let versionNumber = this.jsonGet(data,this.props.unitName + "/system/version")
    if(versionNumber.hasOwnProperty("value")){
      versionNumber = versionNumber.value;
    }
    else{
      versionNumber = "";
    }

    let watchDogTimer = this.jsonGet(data,this.props.unitName + "/system/watchdog")
    if(watchDogTimer.hasOwnProperty("value")){
      watchDogTimer = " PLC watchdog: " + watchDogTimer.value;
    }
    else{
      watchDogTimer = "";
    }

    let waterPumpP3StatusRegister = this.jsonGet(data,this.props.unitName + "/motor/p3/status_register");
    if(waterPumpP3StatusRegister.hasOwnProperty("value")){
      waterPumpP3StatusRegister = waterPumpP3StatusRegister.value;
    }
    else{
      waterPumpP3StatusRegister = 0;
    }
    let airPumpP1StatusRegister = this.jsonGet(data,this.props.unitName + "/motor/p1/status_register");
    if(airPumpP1StatusRegister.hasOwnProperty("value")){
      airPumpP1StatusRegister = airPumpP1StatusRegister.value;
    }
    else{
      airPumpP1StatusRegister = 0;
    }

    let motorValve1StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv1/status_register");
    if(motorValve1StatusRegister.hasOwnProperty("value")){
      motorValve1StatusRegister = motorValve1StatusRegister.value;
    }
    else{
      motorValve1StatusRegister = 0;
    }
    let motorValve2StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv2/status_register");
    if(motorValve2StatusRegister.hasOwnProperty("value")){
      motorValve2StatusRegister = motorValve2StatusRegister.value;
    }
    else{
      motorValve2StatusRegister = 0;
    }
    let motorValve3StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv3/status_register");
    if(motorValve3StatusRegister.hasOwnProperty("value")){
      motorValve3StatusRegister = motorValve3StatusRegister.value;
    }
    else{
      motorValve3StatusRegister = 0;
    }

    let aquasensor1ErrorCode = this.jsonGet(data,this.props.unitName + "/aquasensor/aquasensor1/error_code");
    if(aquasensor1ErrorCode.hasOwnProperty("value")){
      aquasensor1ErrorCode = aquasensor1ErrorCode.value;
    }
    else{
      aquasensor1ErrorCode = 0;
    }

    let lt1PV = this.jsonGet(data,this.props.unitName + "/analog/lt1/pv_filtered");
    if(lt1PV.hasOwnProperty("value")){
      lt1PV = lt1PV.value;
    }
    else{
      lt1PV = 0;
    }
    const lt1MaxLevel = 0.5; // meter
    let pumpSumpLevelPercentage = parseInt((lt1PV/lt1MaxLevel)*100)
    if(pumpSumpLevelPercentage > 100){
      pumpSumpLevelPercentage = 100;
    }
    if(pumpSumpLevelPercentage < 0){
      pumpSumpLevelPercentage = 0;
    }

    let lc1Auto = this.jsonGet(data,this.props.unitName + "/pid/lc1/commando_register");
    if(lc1Auto.hasOwnProperty("value")){
      lc1Auto = lc1Auto.value;
      lc1Auto = (lc1Auto & Math.pow(2,0)) === Math.pow(2,0);
    }
    else{
      lc1Auto = false;
    }

    let verificationCountDownText = <></>;
    let verificationRemainingTime = this.jsonGet(data,this.props.unitName + "/verification/remaining_time");
    if(verificationRemainingTime.hasOwnProperty("value")){
      verificationRemainingTime = verificationRemainingTime.value;
    }
    else{
      verificationRemainingTime = 0;
    }

    let ozoneWashModeText = <></>;
    let ozoneWashModeActive = this.jsonGet(data,this.props.unitName + "/system/ozone_wash_mode/status");
    if(ozoneWashModeActive.hasOwnProperty("value")){
      ozoneWashModeActive = ozoneWashModeActive.value;
    }
    else{
      ozoneWashModeActive = false;
    }

    let communcationErrorText = <></>;
    if(systemCommunicationError){
      communcationErrorText = <text x="960" y="150" fill="rgb(213,0,0)" style={{fontSize:"50",alignmentBaseline:"middle",textAnchor:"middle"}} >COMMUNICATION ERROR
                                <animate attributeName="fill" values="rgb(213,0,0);rgb(0,0,0);rgb(213,0,0)" dur="1s" repeatCount="indefinite" />
                              </text>
          
    }
    else if(verificationRemainingTime > 0){
      // Display verification count down text if not com. error is active
      verificationCountDownText = <text fill={textColor} x="960" y="150" style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{"Verification in progress: " + this.convertSecondsToHHMMSS(verificationRemainingTime)}</text>
    }
    else if(ozoneWashModeActive){
      ozoneWashModeText = <text fill={textColor} x="960" y="150" style={{fontSize:"50",alignmentBaseline:"middle",textAnchor:"middle"}} >Ozone wash mode</text>
    }

    let motorValve1Open = (motorValve1StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let motorValve1Closed = (motorValve1StatusRegister & Math.pow(2,1)) === Math.pow(2,1);
    let motorValve2Open = (motorValve2StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let motorValve2Closed = (motorValve2StatusRegister & Math.pow(2,1)) === Math.pow(2,1);
    let motorValve3Open = (motorValve3StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let airPumpP1Running = (airPumpP1StatusRegister & Math.pow(2,0)) === Math.pow(2,0); 
    let waterPumpP3Running = (waterPumpP3StatusRegister & Math.pow(2,0)) === Math.pow(2,0); 

    return(

      <div style={{width:"100%",height:"100%",backgroundColor:backgroundColor}}>
        <AnalogModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.analogModalCustomProps} show={this.state.showAnalogModal} close={this.closeAnalogModal} dataPath={this.state.analogModalDataPath} data={data}/>
        <MotorModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.motorModalCustomProps} show={this.state.showMotorModal} close={this.closeMotorModal} dataPath={this.state.motorModalDataPath} data={data}/>
        <ValveModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.valveModalCustomProps} show={this.state.showValveModal} close={this.closeValveModal} dataPath={this.state.valveModalDataPath} data={data}/>
        <PIDModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.pidModalCustomProps} show={this.state.showPIDModal} close={this.closePIDModal} dataPath={this.state.pidModalDataPath} data={data}/>
        <AquasensorH2SModal unitName={this.props.unitName} colorTheme={this.props.colorTheme} customProps={this.state.aquasensorH2SModalCustomProps} show={this.state.showAquasensorH2SModal} close={this.closeAquasensorH2SModal} dataPath={this.state.aquasensorH2SModalDataPath} data={data}/>
        <AquasenseVerificationModal auth_token={this.props.auth_token} colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.aquasenseVerificationModalCustomProps} show={this.state.showAquasenseVerificationModal} close={this.closeAquasenseVerificationModal} dataPath={this.state.aquasenseVerificationModalDataPath} data={data}/>


        <div style={{width:"100%", height:"100%"}}>
         <svg style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">

          <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{this.props.unitName.toUpperCase()}</text>
          <text x="960" y="100" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{locationName}</text>
          <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>
          <text x="20" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"start",textAnchor:"start"}} >{watchDogTimer}</text>
          <text x="20" y="130" fill={textColor} style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >{versionNumber}</text>
          {communcationErrorText}
          {verificationCountDownText}
          {ozoneWashModeText}

          {/* Air pipe to from equilibrator to motor valve 2 */}
          <polyline fill={(motorValve2Closed && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="990,500 990,460 1125,460 1125,415 1115,415 1115,450 980,450 980,500" /> 

          {/* Air pipe from motor valve 2 to outdoor air */}
          <polyline fill={(motorValve2Open && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1085,375 1055,375 1055,385 1085,385" /> 
          <text fill={textColor} x="1050" y="385" style={{fontSize:"16",alignmentBaseline:"end",textAnchor:"end"}} >Outdoor air</text>
          
          {/* Air pipe from motor valve 2 to air pump P1 */}
          <polyline fill={((motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1125,345 1125,260 935,260 935,270 1115,270 1115,345" /> 
          
          {/* Air pipe from air pump P1 to motor valve MV1 */}
          <polyline fill={((motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="865,260 795,260 795,345 805,355 805,270 865,270" /> 
          
          {/* Air pipe from motor valve 1 to outdoor air */}
          <polyline fill={(motorValve1Open && (motorValve2Closed || motorValve2Open)  && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="835,375 865,375 865,385 835,385" /> 
          <text fill={textColor} x="870" y="385" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Exhaust air</text>

          {/* Air pipe from motor valve 1 to equilibrator */}
          <polyline fill={(motorValve1Closed && (motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="795,415 795,460 955,460 955,500 965,500 965,450 805,450 805,415" /> 

          {/* Water pipe from motor valve 3 to equilibrator */}
          <polyline fill={(motorValve3Open)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="605,415 605,470 940,470 940,500 930,500 930,480 535,480 535,470 595,470 595,415" /> 
          <text fill={textColor} x="465" y="470" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Water to</text>
          <text fill={textColor} x="465" y="485" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >NH3 unit</text>

          {/* Manual valve on water pipe from mator valve 3 to equilibrator */}
          <polyline fill={backgroundColor} stroke={strokeColor} strokeWidth="1" points="690,470 670,460 670,490 690,480" />
          <rect fill={backgroundColor} stroke={strokeColor} x="690" y="465" width="20" height="20"/>
          <polyline fill={backgroundColor} stroke={strokeColor} strokeWidth="1" points="710,470 730,460 730,490 710,480" />
          <polyline fill="transparent" stroke={strokeColor} strokeWidth="1" points="700,465 700,450 710,450 690,450" />
          <text fill={textColor} x="630" y="505" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Manual throttle valve</text>

          {/* Water pipe inlet motor valve 3 */}
          <polyline fill="rgb(0,100,213)" stroke={strokeColor} strokeWidth="1" points="605,345 605,260 595,260 595,345" /> 
          <text fill={textColor} x="600" y="250" style={{fontSize:"16",alignmentBaseline:"middle",textAnchor:"middle"}} >Water inlet</text>
         
          {/* Aquasensor 1 */}
          <rect fill={aquasensor1ErrorCode > 0?"rgb(213,0,0)":pipeIdleColor} stroke={strokeColor} x="980" y="245" width="90" height="40" ry="10"/> 
          <text fill={textColor} x="984" y="270" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Aquasensor</text>
          <rect onClick={() => this.openAquasensorH2SModal(this.props.unitName + "/aquasensor/aquasensor1", {"objectName":"Aquasensor1"})} fill="transparent" stroke="transparent" strokeWidth="1" x="970" y="235" width="110" height="60"/>  
        
          {/* equilibrator tank */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="910" y="500" width="100" height="400" ry="20"/> 
          <rect fill="rgb(0,100,213)" stroke={strokeColor} x="910" y="500" width="100" height="400" ry="20" clipPath={motorValve3Open?"inset(50% 0% 0% 0%)":"inset(100% 0% 0% 0%)"} />
               
          {/* equilibrator outlet tank */}
          <rect fill={(motorValve3Open)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} x="1020" y="700" width="100" height="200" ry="20"/>

          {/* Water pipe between equilibrator tank and equilibrator outlet tank */}
          <polyline fill={(motorValve3Open)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="950,900 950,960 1080,960 1080,900 1060,900 1060,940 970,940 970,900" />

          {/* pump sump tank */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="1250" y="750" width="150" height="150" ry="10"/> 
          <rect fill="rgb(0,100,213)" stroke={strokeColor} x="1250" y="750" width="150" height="150" ry="10" clipPath={"inset(" + (100-pumpSumpLevelPercentage) + "% 0% 0% 0%)"} />
           
          {/* Water pipe between equilibrator outlet tank and pump sump tank */}
          <polyline fill={(motorValve3Open)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1120,715 1280,715 1280,750 1270,750 1270,725 1120,725" />

          {/* Water pipe to equilibrator outlet tank from NH3 unit */}
          <polyline fill={(motorValve3Open)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1380,750 1380,725 1430,725 1430,715 1370,715 1370,750" />
          <text fill={textColor} x="1435" y="715" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Water from</text>
          <text fill={textColor} x="1435" y="730" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >NH3 unit</text>

          {/* Water pipe overflow from pump sump tank */}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1400,765 1430,765 1430,785 1400,785 1400,765" />
          <text fill={textColor} x="1435" y="770" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Overflow</text>
          <text fill={textColor} x="1435" y="785" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >to aft. tank</text>

          {/* Water pipe from pump sump tank to fish tank */}
          <polyline fill={waterPumpP3Running?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1325,900 1325,965 1430,965 1430,955 1335,955 1335,900 1325,900" />
          <text fill={textColor} x="1435" y="965" style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Fish tank</text>

          {/* level controller LC1, line from pump sump, LC1 and P3 */}
          <polyline fill="transparent" stroke={dottedLineColor} strokeWidth="1" strokeDasharray="5" points="1185,900 1185,960 1295,960" />

          {/* level controller LC1  */}
          <SvgPID objectName="LC1" objectDescription="Level controller 1" openModal={this.openPIDModal} x={1160} y={935} colorTheme={this.props.colorTheme}
           dataPath={this.props.unitName + "/pid/lc1"} data={data} userName={this.props.userName} stepIncrement="0.01" pvMin="0.0" pvMax="0.5" />

          {/* Air pump P1*/}
          <SvgMotor objectName="P1" objectDescription="Air pump" openModal={this.openMotorModal} x={900} y={265} dataPath={this.props.unitName + "/motor/p1"} data={data} showResetOutput={false} 
          showAutoSpeedSetpoint={false} userName={this.props.userName} idleDirection="down" runningDirection="left" hideFeedbackSpeed={true} colorTheme={this.props.colorTheme}/>

          {/* Water pump P3*/}
          <SvgMotor objectName="P3" objectDescription="Water pump" openModal={this.openMotorModal} x={1330} y={960} dataPath={this.props.unitName + "/motor/p3"} data={data} showResetOutput={false} 
          showAutoSpeedSetpoint={false} userName={this.props.userName} idleDirection="up" runningDirection="right" titlePositionX="-50" titlePositionY="30" colorTheme={this.props.colorTheme}/>

          {/* Motor valve MV1*/}
          <SvgValve objectName="MV1" valve1Direction="up" valve1OpenColor="rgb(0,213,0)" valve1CloseColor="rgb(0,213,0)" 
                                     valve2Direction="down" valve2OpenColor={pipeIdleColor} valve2CloseColor="rgb(0,213,0)" 
                                     valve3Direction="right" valve3OpenColor="rgb(0,213,0)" valve3CloseColor={pipeIdleColor} 
          showAutoPositionSetpoint={false} showManualPositionSetpoint={false} titlePositionX="45" titlePositionY="-2" colorTheme={this.props.colorTheme}
          objectDescription="Air Motor valve" openModal={this.openValveModal} x={800} y={380} dataPath={this.props.unitName + "/valve/mv1"} data={data} userName={this.props.userName}/>

          {/* Motor valve MV2*/}
          <SvgValve objectName="MV2" valve1Direction="up" valve1OpenColor="rgb(0,213,0)" valve1CloseColor="rgb(0,213,0)" 
                                     valve2Direction="down" valve2OpenColor={pipeIdleColor} valve2CloseColor="rgb(0,213,0)" 
                                     valve3Direction="left" valve3OpenColor="rgb(0,213,0)" valve3CloseColor={pipeIdleColor} 
          showAutoPositionSetpoint={true} showManualPositionSetpoint={true} titlePositionX="-45" titlePositionY="-2" colorTheme={this.props.colorTheme}
          objectDescription="Air Motor valve" openModal={this.openValveModal} x={1120} y={380} dataPath={this.props.unitName + "/valve/mv2"} data={data} userName={this.props.userName}/>

          {/* Motor valve MV3*/}
          <SvgValve objectName="MV3" valve1Direction="up" valve1OpenColor="rgb(0,213,0)" valve1CloseColor={pipeIdleColor} 
                                     valve2Direction="down" valve2OpenColor="rgb(0,213,0)" valve2CloseColor={pipeIdleColor}
          showAutoPositionSetpoint={false} showManualPositionSetpoint={false} titlePositionX="45" titlePositionY="-2" colorTheme={this.props.colorTheme}
          objectDescription="Water inlet motor valve" openModal={this.openValveModal} x={600} y={380} dataPath={this.props.unitName + "/valve/mv3"} data={data} userName={this.props.userName}/>

          <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} openModal={this.openAnalogModal} x={160} y={300} colorTheme={this.props.colorTheme}
           instrumentTag={"O2"} dataPath={this.props.unitName + "/analog/o2_sat"} data={data} userName={this.props.userName} decimals="1" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={160} y={430} colorTheme={this.props.colorTheme}
           instrumentTag={"CO2"} dataPath={this.props.unitName + "/analog/co2_mgl"} data={data} userName={this.props.userName} decimals="2" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={20.0} openModal={this.openAnalogModal} x={300} y={300} colorTheme={this.props.colorTheme}
           instrumentTag={"O2"} dataPath={this.props.unitName + "/analog/o2_mgl"} data={data} userName={this.props.userName} decimals="2" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={300} y={430} colorTheme={this.props.colorTheme}
           instrumentTag={"H2S"} dataPath={this.props.unitName + "/analog/h2s_ugl"} data={data} userName={this.props.userName} decimals="2" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="ALK" objectDescription="Alkalinity mg/l" pvMin={0.0} pvMax={500.0} openModal={this.openAnalogModal} x={160} y={560} colorTheme={this.props.colorTheme}
           instrumentTag={"ALK"} dataPath={this.props.unitName + "/analog/alkalinity_mgl"} data={data} userName={this.props.userName} decimals="1" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} openModal={this.openAnalogModal} x={1075} y={660} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"PH"} dataPath={this.props.unitName + "/analog/ph"} data={data} userName={this.props.userName}/>

          {/* Sensor line for pH to equilibrator outlet tank */}
          <polyline stroke={strokeColor} strokeWidth="2" points="1075,680 1075,700" />

          <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={1200} y={660} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"TT"} dataPath={this.props.unitName + "/analog/water_temperature"} data={data} userName={this.props.userName}/>         

          <SvgAnalog objectName="ST1" objectDescription="Salinity" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={1325} y={660} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"ST"} dataPath={this.props.unitName + "/analog/salinity"} data={data} userName={this.props.userName}/>         

          {/* Sensor line for LT1 to pump sump tank */}
          <polyline stroke={strokeColor} strokeWidth="2" points="1210,875 1250,875" />

          <SvgAnalog objectName="LT1" objectDescription="Level pump sump" pvMin={0.0} pvMax={1.0} openModal={this.openAnalogModal} x={1185} y={880} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"LT"} dataPath={this.props.unitName + "/analog/lt1"} data={data} userName={this.props.userName} decimals="3" alarmTextPosX={10} alarmTextPosY={40}/>
          {/* Sensor line for LT1 to pump sump tank */}
          <polyline stroke={strokeColor} strokeWidth="2" points="1210,875 1250,875" />


          {/* Verification parameter box */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="100" y="1000" width="100" height="50" ry="10"/>
          <text fill={textColor} x="150" y="1025" style={{fontSize:"16",alignmentBaseline:"middle",textAnchor:"middle"}} >Verification</text>
          <rect onClick={() => this.openAquasenseVerificationModal(this.props.unitName + "/verification", {"objectName":"Verification","objectDescription":"Verification","userName":this.props.userName}) } fill="transparent" stroke="transparent" x="100" y="1000" width="100" height="50" ry="10"/>
                   
          </svg>
        </div>
 
      </div>
    )

    }

}