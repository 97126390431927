import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class AlarmLogWindow extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        alarmLogDays:1,
        alarmLogList:[]
      }
    }

  componentDidMount() {
    this.getAlarmLog();
  }

  getAlarmLog = (days = 1) => {
  if(this.props.auth_token){
    this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "alarm_log?auth_token=" + this.props.auth_token;
    url += "&days=" + days;
    fetch(url)
     .then((response) => {
       if(!response.ok) throw new Error(response);
       else return response.json();
     })
     .then((data) => {
       this.setState({ isLoading:false, alarmLogList:data });
     })
     .catch((error) => {
       this.setState({ isLoading: false, error:true });
     });
    }
  }

  formatDate = (dateObj) => {
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

  handleAlarmLogDaysSelect = (event) => {
    this.setState({alarmLogDays:event.target.value});
    this.getAlarmLog(event.target.value);
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let alarmLogDiv = this.state.alarmLogList.map((jsonData) => {
        let alarmTimeActiveUTC = jsonData.alarm_active_time_utc;
        let alarmTimeActive = "";
        if(alarmTimeActiveUTC){ // Convert UTC to local time for time alarm active
          alarmTimeActive = this.formatDate(new Date(alarmTimeActiveUTC + " UTC"));
        }
        let alarmTimeInactiveUTC = jsonData.alarm_inactive_time_utc;
        let alarmTimeInactive = "";
        if(alarmTimeInactiveUTC){ // Convert UTC to local time for time alarm active
          alarmTimeInactive = this.formatDate(new Date(alarmTimeInactiveUTC + " UTC"));
        }
        let alarmLabel = jsonData.alarm_label;
        let systemName = jsonData.system_name;
        let alarmPriority = jsonData.alarm_priority;
        let alarmName = jsonData.alarm_name;
        let uniqueKey = alarmTimeActive + systemName + alarmLabel + alarmName;
        let object = <tr key={uniqueKey}>
                     <td style={{color:textColor}}>{alarmTimeActive}</td>
                     <td style={{color:textColor}}>{alarmTimeInactive}</td>
                     <td style={{color:textColor}}>{systemName}</td>
                     <td style={{color:textColor}}>{alarmLabel}</td>
                     <td style={{color:textColor}}>{alarmName}</td>
                     <td style={{color:textColor}}>{alarmPriority}</td>
                      </tr>
        return object;
    });

    let numberOfAlarmLog = alarmLogDiv.length
    
    return(
      <div style={{width:"inherit",height:"inherit", backgroundColor: backgroundColor}}>

 
        <div className="container-fluid">

          <div className="row">
            <div className="col-12 text-center">
                <h3 style={{color:textColor}}> Alarm log </h3>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-4 text-start">
                <div>
                  <select style={{minWidth:"100px", maxWidth:"100px", backgroundColor: backgroundColor, color:textColor}} value={this.state.alarmLogDays} onChange={(event) => this.handleAlarmLogDaysSelect(event)} className="form-select">
                      <option value="1">1d</option>
                      <option value="7">7d</option>
                      <option value="30">30d</option>
                  </select>
                </div>
            </div>
            <div className="col-4 text-center">
                
            </div>
            <div className="col-4 text-end">
                <p style={{fontSize:"calc(50% + 0.4vw)",color:textColor}}> Total alarms: {numberOfAlarmLog}</p>
            </div>
          </div>

          
          <div className="table-responsive" style={{maxHeight:"70vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">Active time</th>
                  <th style={{color:textColor}} scope="col">Inactive time</th>
                  <th style={{color:textColor}} scope="col">System name</th>
                  <th style={{color:textColor}} scope="col">Label</th>
                  <th style={{color:textColor}} scope="col">Name</th>
                  <th style={{color:textColor}} scope="col">Priority</th>
                </tr>
              </thead>
              <tbody>

              {alarmLogDiv}

              </tbody>
            </table>
          </div> 

        </div> {/* container-fluid */}
      </div>
    )
  }

}
